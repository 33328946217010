import { Container, Image } from "react-bootstrap";
import { CharacterModel } from "../../hooks/characters/CharacterModel";
import { useGetCharacterDetails } from "../../hooks/characters/UseCharacters";
import { LessonStateInCurriculum } from "../../hooks/lesson-progression/LessonStateInCurriculum";
import { LessonReferenceModel } from "../../hooks/lesson/LessonReferenceModel";
import LinkTransformer from "../../services/markdown/LinkTransformer";
import { CommonLoading } from "../loading/CommonLoading";
import "./AchievementsGalleryLessonItem.css"

export interface IAchievementsGalleryLessonItemProps {
    lessonProgress: LessonStateInCurriculum;
    lessonReference: LessonReferenceModel;
}

export const AchievementsGalleryLessonItem = ({ lessonProgress, lessonReference }: IAchievementsGalleryLessonItemProps) => {
    const { data: character, isLoading: isLoadingCharacter, error: characterError } = useGetCharacterDetails(lessonReference.characterId);
    const isLessonCompleted = lessonProgress.isCompleted;

    const getCharacterImage = (character?: CharacterModel) => {
        if (!character) {
            return "";
        }

        const stateUrl = isLessonCompleted ? character?.dashboard?.completed?.url : character?.dashboard?.notStarted?.url;
        const url = LinkTransformer.resolveRemoteStorageUrl(stateUrl);
        return url;
    }

    if (characterError) {
        throw characterError;
    }

    if (isLoadingCharacter) {
        return (
            <Container className="achievement-item-container">
                <CommonLoading />
            </Container>
        );
    }

    const characterImage = getCharacterImage(character);

    return (
        <Container className="achievement-item-container d-flex justify-content-center">
            {isLessonCompleted
                ? <Image className="character-image" src={characterImage} />
                : <img className="character-image character-image-locked" src={characterImage} />
            }
            {!isLessonCompleted && <Image src="achievements/lock.png" className="lock-image" />}
        </Container>
    );
};