import { useEffect, useRef } from "react";
import videojs from 'video.js';
import { ReadyCallback } from "video.js/dist/types/component";
import Player from "video.js/dist/types/player";

interface Props {
  id: string;
  className?: string;
  src: string;
  options?: any;
  onReady?: ReadyCallback;
  subtitles?: {
    url: string;
    srcLang: string;
  }
}

// Inspired by: https://videojs.com/guides/react/.
export const VideoPlayer = (props: Props) => {
  const videoRef = useRef(null);
  const playerRef = useRef<Player | null>(null);
  const { options, onReady } = props;

  const videoElementId = `video-player-${props.id}`;

  useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = document.getElementById(videoElementId)!;

      const defaultOptions = {
        controls: true,
        controlBar: {
          pictureInPictureToggle: false
        },
      };
      const player = playerRef.current = videojs(videoElement, Object.assign(defaultOptions, options), () => {
        videojs.log('player is ready');
        onReady && onReady();
      });

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    // Note: This return function runs when the component is unmounted.
    const whenUnmounting = () => {
      if (player && !player.isDisposed()) {
        videojs.log('disposing player');
        player.dispose();
        playerRef.current = null;
      }
    };
    return whenUnmounting;
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef}>
        <video
          id={videoElementId}
          className={`video-js ${props.className}`}
          controls
          preload="auto"
        // data-setup='{"controlBar": {"pictureInPictureToggle": false}}' // Removes picture in picture button from control bar.
        >
          <source src={props.src} type="video/mp4" />

          {props.subtitles && <track
            src={props.subtitles.url}
            kind="subtitles"
            srcLang={props.subtitles.srcLang}
            label={props.subtitles.srcLang}
            default
          />}

          <p className="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a
            web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
          </p>
        </video>
      </div>
    </div>
  );
}