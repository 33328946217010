import * as axios from "axios";
import { BearerTokenAuthProvider, createApiClient, TeamsUserCredential } from "@microsoft/teamsfx";
import config from "../../config";

import { IUser } from "./dtos/IUser";
import { IUserLessonsProgression } from "./dtos/ILessonsProgression";
import { ILessonProgressMutation } from "./dtos/ILessonProgressMutation";
import { ICurriculum } from "./dtos/ICurriculum";
import { ILessonRelevanceReason } from "./dtos/ILessonRelevanceReason";
import { IUserIntakeProgress } from "./dtos/IUserIntakeProgress";
import { IUserProfile } from "./dtos/IUserProfile";
import { IUserLocalization } from "./dtos/IUserLocalization";
import { ICurriculumReasoning } from "./dtos/ICurriculumReasoning";
import { IUserTenantStats } from "./dtos/IUserTenantStats";

export class AzureFunctions {
    private credential: TeamsUserCredential;
    private apiBaseUrl: string;

    constructor(credential: TeamsUserCredential) {
        this.credential = credential;
        this.apiBaseUrl = new URL("/api/", config.apiEndpoint).toString();
    }

    public async getUserInfo(): Promise<IUser> {
        return this.getUserPersonalInfoInternal();
    }

    public async postLessonProgress(lesson: ILessonProgressMutation): Promise<void> {
        return this.callPostFunction("setLessonProgress", lesson)
    }

    public async getLessonsProgression(): Promise<IUserLessonsProgression> {
        return await this.callGetFunction<IUserLessonsProgression>("getLessonsProgression");
    }

    public async getCurriculum() {
        return await this.callGetFunction<ICurriculum>("getCurriculum");
    }

    public async getCurriculumReasoning(locale: string) {
        return await this.callGetFunction<ICurriculumReasoning>("getCurriculumReasoning", { locale });
    }

    public async getLessonRelevanceReason(lessonId: string, locale: string) {
        return await this.callGetFunction<ILessonRelevanceReason>("getLessonRelevanceReason", { lessonId, locale });
    }

    public async getUserIntakeProgress({ isRetry = false } = {}): Promise<IUserIntakeProgress> {
        try {
            return await this.callGetFunction<IUserIntakeProgress>("getUserIntakeProgress");
        } catch (err) {
            if (!isRetry && err instanceof AzureFunctions.AuthorizationError) {
                await this.credential.login(["User.Read"]);

                return this.getUserIntakeProgress({ isRetry: true });
            }

            throw err;
        }
    }

    public async resetUser() {
        return await this.callPostFunction("resetUser", {});
    }

    public async getUserProfile() {
        return await this.callGetFunction<IUserProfile>("getUserProfile");
    }

    public async setUserProfile(userProfile: IUserProfile) {
        return await this.callPostFunction("setUserProfile", {
            userProfile: userProfile
        });
    }

    public async setUserLocalization(userLocalization: IUserLocalization) {
        await this.callPostFunction("setUserLocalization", {
            localization: userLocalization,
        });
    }

    public async getUserTenantStats() {
        return await this.callGetFunction<IUserTenantStats>("getUserTenantStats");
    }

    public async debugReminderNotification(user: IUser, locale: string, reminderStatus: string) {
        return await this.callPostFunction("debugReminderNotification", {
            userId: user.userId,
            tenantId: user.tenantId,
            locale: locale,
            reminderStatus: reminderStatus,
        })
    }

    public async debugProcessTenantStats() {
        return await this.callPostFunction("debugProcessTenantStats", {});
    }

    private async getUserPersonalInfoInternal({ isRetry = false } = {}): Promise<IUser> {
        try {
            return this.callGetFunction<IUser>("getTeamsUserProfile");
        } catch (err) {
            if (!isRetry && err instanceof AzureFunctions.AuthorizationError) {
                await this.credential.login(["User.Read"]);

                return this.getUserPersonalInfoInternal({ isRetry: true });
            }

            throw err;
        }
    }

    private async callGetFunction<TResult>(functionName: string, params?: object): Promise<TResult> {
        try {
            const apiClient = await this.buildApiClient();

            const response = await apiClient.get<TResult>(functionName, {
                params: params
            });

            return response.data;
        } catch (err: unknown) {
            this.processError(err as Error);
            throw err;
        }
    }

    private async callPostFunction(functionName: string, data: any) {
        try {
            const apiClient = await this.buildApiClient();

            const response = await apiClient.post(functionName, data);

            return response.data;
        } catch (err: unknown) {
            this.processError(err as Error);
            throw err;
        }
    }

    private processError(err: Error) {
        if (axios.default.isAxiosError(err)) {
            let funcErrorMsg = "";

            if (err?.response?.status === 500 || err?.response?.status === 403 || err?.response?.status === 401) {
                throw new AzureFunctions.AuthorizationError("The application may not be authorized.");
            } else if (err?.response?.status === 404) {
                funcErrorMsg = `There may be a problem with the deployment of Azure Function App, please deploy Azure Function (Run command palette "Teams: Deploy") first before running this App`;
            } else if (err.message === "Network Error") {
                funcErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision" and "Teams: Deploy") first before running this App`;
                }
            } else {
                funcErrorMsg = err.message;
                if (err.response?.data?.error) {
                    funcErrorMsg += ": " + err.response.data.error;
                }
            }

            throw new Error(funcErrorMsg);
        }
        throw err;
    }

    private async buildApiClient(): Promise<ReturnType<typeof createApiClient>> {
        return createApiClient(
            this.apiBaseUrl,
            new BearerTokenAuthProvider(async () => (await this.credential.getToken(""))!.token)
        );
    }
}

export namespace AzureFunctions {
    export class AuthorizationError extends Error {
        constructor(message: string) {
            super(message);
        }
    }
}


