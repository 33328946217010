import { app } from "@microsoft/teams-js";

const openCalendar = async ({ subject, content, startTime, endTime }: ITeamsCalendarReminder) => {
    endTime = endTime || new Date(startTime.getTime() + 30 * 60 * 1000); // Default to a meeting of 30 minutes.

    const subjectEncoded = encodeURIComponent(subject);
    const contentEncoded = encodeURIComponent(content);
    const startTimeEncoded = encodeURIComponent(startTime.toISOString());
    const endTimeEncoded = encodeURIComponent(endTime.toISOString());

    // NOTE: using the teams-js calendar namespace to compose a new meeting throws a 'not supported' exception. So we're stuck to constructing the deeplink ourselves.
    const deeplinkUrl = `https://teams.microsoft.com/l/meeting/new?subject=${subjectEncoded}&content=${contentEncoded}&startTime=${startTimeEncoded}&endTime=${endTimeEncoded}`;
    await app.openLink(deeplinkUrl);
}

const useCalendar = () => {
    return { openCalendar };
}

export interface ITeamsCalendarReminder {
    subject: string;
    content: string;
    startTime: Date;
    endTime?: Date;
}

export default useCalendar;