import i18next from "i18next";
import config from "../../config";
import { app } from "@microsoft/teams-js";

const initializeLanguage = async () => {
    const context = await app.getContext();
    const appLocale = context.app.locale;
    setLanguage(appLocale);    
};

const setLanguage = (locale: string) => {
    const supportedLocale = getSupportedLocale(locale);
    i18next.changeLanguage(supportedLocale);
    if(config.debugEnabled) {
        console.log(`[useLanguage] Set locale ${locale} to ${supportedLocale}`);
    }
};

const getCurrentLanguage = () => {
    return i18next.language;
}

const getSupportedLocale = (locale: string) => {
    if(!locale) {
        return config.fallbackLocale;
    }

    locale = locale.toLocaleLowerCase();
    const supportedLocale = config.supportedLocales.find(supportedLocale => locale.startsWith(supportedLocale));
    return supportedLocale ?? config.fallbackLocale;
};

export const useLanguage = () => {
    return {
        initializeLanguage,
        setLanguage,
        getCurrentLanguage,
        getSupportedLocale,
    };
}