import { MediaReference } from "../shared/MediaReference";
import { SubtitlesReference } from "../shared/SubtitlesReference";
import { VideoReference } from "../shared/VideoReference";

export class LessonContentModel {
    dto: LessonContentModel.LessonContentDto;

    constructor(dto: LessonContentModel.LessonContentDto) {
        this.dto = dto;
    }

    get id(): string {
        return this.dto.id;
    }

    get lessonName(): string {
        return this.dto.lessonName;
    }

    get lessonType(): string {
        return this.dto.lessonType;
    }

    get previewHeader(): string {
        return this.dto.preview.header;
    }

    get previewReason(): string {
        return this.dto.preview.reason;
    }

    get previewObjective(): string {
        return this.dto.preview.objective;
    }

    get previewThumbnail(): any {
        return this.dto.preview.thumbnail;
    }

    get isMandatory(): boolean {
        return this.dto.lessonAttributes.mandatory;
    }

    get isPriority(): boolean {
        return this.dto.lessonAttributes.priority;
    }

    get m365Apps(): string[] {
        return this.dto.lessonAttributes.m365apps;
    }

    get aiComplexity(): string {
        return this.dto.lessonAttributes.aiComplexity;
    }

    get lessonSteps(): BaseLessonStepModel[] {
        return this.dto.lessonSteps;
    }

    get characterId(): string {
        return this.dto.character?.id;
    }
}

export interface Illustration extends MediaReference { }

export interface BaseLessonStepModel {
    id: string;
    componentType: string;
}

export interface LessonImagePageModel extends BaseLessonStepModel {
    header: string;
    body: string;
    illustration: Illustration;
}

export interface LessonCalendarPageModel extends BaseLessonStepModel {
    header: string;
    body: string;
    illustration: Illustration;
    meetingSubject: string;
    meetingContent: string;
}

export interface CoachPracticeReference {
    id: string;
}

export interface LessonCoachPractisePageModel extends BaseLessonStepModel {
    header: string;
    body: string;
    coachPractice: CoachPracticeReference
}

export interface LessonVideoPageModel extends BaseLessonStepModel {
    header: string;
    body: string;
    video: VideoReference;
    subtitles: SubtitlesReference;
}

export interface LessonPreviewPageModel extends BaseLessonStepModel {
    header: string;
    reason: string;
    objective: string;
}

export namespace LessonContentModel {
    export interface LessonContentDto {
        id: string;
        lessonName: string;
        createdAt: string;
        updatedAt: string;
        publishedAt: string | null;
        locale: string;
        lessonType: string;
        lessonAttributes: {
            id: string;
            mandatory: boolean;
            priority: boolean;
            m365apps: string[];
            aiComplexity: string;
        };
        preview: {
            id: string;
            header: string;
            reason: string;
            objective: string;
            thumbnail: Illustration;
        };
        character: {
            id: string;
        }
        lessonSteps: BaseLessonStepModel[];
    }
}