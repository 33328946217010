import { BaseLessonStepModel } from "../lesson/LessonContentModel";


export class OnboardingContentModel {
    dto: OnboardingContentModel.OnboardingContentDto;

    constructor(dto: OnboardingContentModel.OnboardingContentDto) {
        this.dto = dto;
    }

    get id(): string {
        return this.dto.id;
    }

    get steps(): BaseLessonStepModel[] {
        return this.dto.steps;
    }
}

export namespace OnboardingContentModel {
    export interface OnboardingContentDto {
        id: string;
        createdAt: string;
        updatedAt: string;
        publishedAt: string | null;
        locale: string;
        steps: BaseLessonStepModel[];
    }
}
