import { defaultUrlTransform } from "react-markdown";

import config from "../../config";

class LinkTransformer {
  static resolveRemoteStorageUrl(relativeUrl: string) {
    return defaultUrlTransform(new URL(relativeUrl, config.storageContainerEndpoint as string).toString());
  }

  static resolveMarkdownMediaUrl(url: string, key: string | undefined = undefined, node: import('hast').Element | undefined = undefined): string {
    if (url.startsWith("http") || key !== "src" || node?.tagName !== "img") {
      return url;
    }

    return LinkTransformer.resolveRemoteStorageUrl(url);
  }
}

export default LinkTransformer;