import { createContext, useContext, useEffect, useState } from "react";
import { useGetChaptersProgression } from "../../hooks/lesson-progression/UseLessonProgression";

export const DashboardProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [currentChapterId, setCurrentChapterId] = useState(0);
    const { data: chaptersProgression, isLoading: isChaptersProgressionLoading, error: chaptersProgressionError } = useGetChaptersProgression();

    // TODO - properly handle errors
    const error = chaptersProgressionError;
    if (error) {
        throw error
    }

    const isLoading = isChaptersProgressionLoading || !chaptersProgression;

    useEffect(() => {
        if(isLoading){
            return;
        }
        const lastAvailableChapter = chaptersProgression!.findLast(chapterProgress => chapterProgress.isAvailable);
        setCurrentChapterId(lastAvailableChapter?.chapter.id ?? 0);

    }, [isLoading, chaptersProgression]);

    const context: DashboardContextType = {
        currentChapterId,
        setCurrentChapterId,
        isLoading,
    };

    return (
        <DashboardContext.Provider value={context}>
            {children}
        </DashboardContext.Provider>
    );
};

interface DashboardContextType {
    currentChapterId: number;
    setCurrentChapterId: (chapterId: number) => void;
    isLoading: boolean;
}

const DashboardContext = createContext<DashboardContextType | undefined>(undefined);

export const useDashboardContext = () => {
    const context = useContext(DashboardContext)
    if (!context) {
        throw new Error('useDashboardContext must be used within a DashboardProvider')
    }
    return context
}
