import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useContext } from "react";
import { TeamsFxContext } from "../../components/Context";
import { AzureFunctions } from "../../services/backend/AzureFunctions";
import { ILessonRelevanceReason } from "../../services/backend/dtos/ILessonRelevanceReason";
import { useLanguage } from "../language/useLanguage";

const defaultStaleTime = 5 * 60 * 1000; // 5 minutes

export const useGetLessonRelevanceReason = (lessonId: string): UseQueryResult<ILessonRelevanceReason, Error> => {
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
    }

    const { getCurrentLanguage } = useLanguage();
    const locale = getCurrentLanguage();

    return useQuery<ILessonRelevanceReason, Error>({
        queryKey: ["useGetLessonRelevanceReason", locale, lessonId],
        queryFn: async () => {
            const lessonRelevanceReason = await new AzureFunctions(teamsUserCredential).getLessonRelevanceReason(lessonId, locale);
            return lessonRelevanceReason;
        },
        staleTime: defaultStaleTime,
        enabled: !!lessonId,
    });
}
