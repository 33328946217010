import React from 'react'
import './OnboardingStepper.css'
import { Button, Stack } from 'react-bootstrap';

export interface IOnboardingStepperProps {
    stepsCount: number;
    currentStepIndex: number;
    onStepPressed: (index: number) => void;
}

export const OnboardingStepper: React.FC<IOnboardingStepperProps> = ({ currentStepIndex, onStepPressed, stepsCount }) => {
    const getStepClassName = (index: number) => {
        const isSelected = index === currentStepIndex;
        return `${isSelected ? 'onboarding-step-selected' : 'onboarding-step-unselected'}`;
    }

    return (
        <Stack direction="horizontal" gap={3} className='justify-content-center'>
            {Array.from({ length: stepsCount }, (_, index) => (
                <div key={index} className='onboarding-step'>
                    <button className={getStepClassName(index)} onClick={() => onStepPressed(index)} />
                </div>
            ))}
        </Stack>
    )
}
