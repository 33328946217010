import axios from "axios";
import { LessonContentModel } from "./LessonContentModel";
import { LessonReference, LessonReferenceModel } from "./LessonReferenceModel";
import config from "../../config";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useLanguage } from "../language/useLanguage";

const defaultStaleTime = 5 * 60 * 1000; // 5 minutes
const remoteStorageEndpoint: string = config.storageContainerEndpoint;
const lessonManifestRelativeUrl: string = "lessons-manifest.json";

export const useGetLessonReferences = (): UseQueryResult<LessonReferenceModel[], Error> => {
    const { getCurrentLanguage } = useLanguage();
    const locale = getCurrentLanguage();
    return useQuery<LessonReferenceModel[], Error>({
        queryKey: ["useGetListLessonPreviews", locale],
        queryFn: () => listLessons(locale),
        staleTime: defaultStaleTime,
    });
}

export const useGetLessonDetails = (lessonId?: string): UseQueryResult<LessonContentModel, Error> => {
    const { getCurrentLanguage } = useLanguage();
    const locale = getCurrentLanguage();
    const { data: lessons, isLoading: isLoadingLessons } = useGetLessonReferences();
    return useQuery<LessonContentModel, Error>({
        queryKey: ["useGetLessonDetails", locale, lessonId],
        queryFn: () => getLessonDetails(lessons!, lessonId!),
        staleTime: defaultStaleTime,
        enabled: !!lessonId && !isLoadingLessons,
    });
}

const getLessonManifestUrl = (locale: string) => {
    return new URL(`${locale}/${lessonManifestRelativeUrl}`, remoteStorageEndpoint);
}

const listLessons = async (locale: string): Promise<LessonReferenceModel[]> => {
    const manifestLocation = getLessonManifestUrl(locale).toString();
    const response = await axios.get(manifestLocation);
    const lessons = (response.data.lessonReferences as LessonReference.LessonReferenceDto[]).map(lessonPreviewDto => new LessonReferenceModel(lessonPreviewDto));
    return lessons;
}

const getLessonDetails = async (lessons: LessonReferenceModel[], lessonId: string) => {
    const lessonPreview = lessons!.find((lesson) => lesson.id === lessonId);
    if (!lessonPreview) {
        throw new Error(`Lesson ${lessonId} not found`);
    }
    const lessonContentUrl = new URL(lessonPreview.contentRef, remoteStorageEndpoint);
    const response = await axios.get(lessonContentUrl.toString());
    const lessonContent = new LessonContentModel(response.data as LessonContentModel.LessonContentDto);
    return lessonContent;
}