import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { IUser } from "../../services/backend/dtos/IUser";
import { useContext } from "react";
import { TeamsFxContext } from "../../components/Context";
import { AzureFunctions } from "../../services/backend/AzureFunctions";
import { LessonProgressionQueryKeys } from "../lesson-progression/UseLessonProgression";
import { UserIntakeQueryKeys } from "../user-intake-progress/useUserIntakeProgress";

const defaultStaleTime = 5 * 60 * 1000; // 5 minutes

export enum UserQueryKeys {
    UseUserInfo = "useUserInfo",
    ResetUser = "resetUser",
}

export const useUserInfo = (): UseQueryResult<IUser, Error> => {
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
    }

    return useQuery<IUser, Error>({
        queryKey: [UserQueryKeys.UseUserInfo],
        queryFn: async () => {
            return await new AzureFunctions(teamsUserCredential).getUserInfo();
        },
        staleTime: defaultStaleTime,
    });
}

export const useResetUser = (): UseMutationResult<void, Error, void> => {
    const queryClient = useQueryClient();
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
    }
    return useMutation<void, Error, void>({
        mutationKey: [ UserQueryKeys.ResetUser ],
        mutationFn: async () => {
            return await new AzureFunctions(teamsUserCredential).resetUser();
        },
        onSuccess: async () => {
            // NB: invalidate ALL queries to force a re-fetch
            await queryClient.invalidateQueries();
        }
    });
}