import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DashboardProvider } from "../../context/dashboard/DashboardContext";
import useAnalytics from "../../hooks/analytics/UseAnalytics";
import { useResumeLesson } from "../../hooks/lesson-launcher/UseResumeLesson";
import { useGetUserIntakeProgress } from "../../hooks/user-intake-progress/useUserIntakeProgress";
import { CommonLoading } from "../loading/CommonLoading";
import "./Dashboard.css";
import { DashboardContent } from "./DashboardContent";
import { DashboardHeader } from "./DashboardHeader";
import { LessonCardList } from "./LessonCardList";

export const Dashboard = () => {
  const { data: userIntakeProgress, isLoading: isUserIntakeProgressLoading, error: userIntakeProgressError, } = useGetUserIntakeProgress();
  const { trackPageView } = useAnalytics();
  const { isLoading: isResumeLessonLoading, hasLessonSession, resumeLesson, error: resumeLessonError } = useResumeLesson();
  const navigate = useNavigate();

  const isLoading = isUserIntakeProgressLoading || isResumeLessonLoading;

  useEffect(() => {
    trackPageView("/dashboard", "Dashboard");
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const isFirstRun = !userIntakeProgress!.isUserProfileReady || !userIntakeProgress!.isCurriculumReady;
    if (isFirstRun) {
      navigate(`/intake`);
    }
  }, [isLoading])

  useEffect(() => {
    if (isLoading || !hasLessonSession) {
      return;
    }
    resumeLesson();
  }, [isLoading, hasLessonSession])

  if (isLoading) {
    return <CommonLoading />
  }

  // TODO - properly handle error
  const error = userIntakeProgressError || resumeLessonError;
  if (error) {
    throw error;
  }

  return (
    <DashboardProvider>
      <Container
        className="dashboard-container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <DashboardHeader />
        <DashboardContent>
          <LessonCardList />
        </DashboardContent>
      </Container>
    </DashboardProvider>
  );
};
