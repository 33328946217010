import { LessonReferenceModel } from "../../../hooks/lesson/LessonReferenceModel";
import { LessonCard } from "../LessonCard";

export const LessonCardList = ({ lessons }: ILessonCardListProps) => <>
    <div className="row d-flex justify-content-left">
        {
            lessons.map((lesson, index) => <LessonCard key={`lesson-card-${index}`} lesson={lesson} />)
        }
    </div>
</>;

export interface ILessonCardListProps {
    lessons: LessonReferenceModel[];
}

