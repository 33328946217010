import axios from "axios";
import config from "../../config";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { CoachPracticeReference, CoachPracticeReferenceModel } from "./CoachPracticeReferenceModel";

const defaultStaleTime = 5 * 60 * 1000; // 5 minutes
const remoteStorageEndpoint: string = config.storageContainerEndpoint;
const coachPracticeManifestRelativeUrl: string = "coach-practices-manifest.json";

export const useGetCoachPracticeReferences = (language: string): UseQueryResult<CoachPracticeReferenceModel[], Error> => {
    return useQuery<CoachPracticeReferenceModel[], Error>({
        queryKey: ["useGetListCoachPracticePreviews"],
        queryFn: listCoachPractices.apply(null, [language]),
        staleTime: defaultStaleTime,
    });
}

const fetchCoachPractices = async (coachPracticeManifestLocation: URL): Promise<CoachPracticeReferenceModel[]> => {
    const response = await axios.get(coachPracticeManifestLocation.toString());
    const coachPractices = (response.data.coachPracticeReferences as CoachPracticeReference.CoachPracticeReferenceDto[]).map(coachPracticePreviewDto => new CoachPracticeReferenceModel(coachPracticePreviewDto));

    return coachPractices;
}

const listCoachPractices = (language: string): (() => Promise<CoachPracticeReferenceModel[]>) => {
    const languagePathComponent = language ? `${language}/` : "";
    const coachPracticeManifestLocation = new URL(coachPracticeManifestRelativeUrl, new URL(languagePathComponent, remoteStorageEndpoint));

    return async () => {
        return await fetchCoachPractices(coachPracticeManifestLocation);
    }
}