import { Container, Row } from "react-bootstrap";
import "./AchievementsGallery.css"
import { useGetCategoriesProgression } from "../../hooks/lesson-progression/UseCategoryProgression";
import { CommonLoading } from "../loading/CommonLoading";
import { AchievementsCategoryList } from "./AchievementsCategoryList";
import { motion, Transition, Variants } from "framer-motion";

export const AchievementsGalleryContent = () => {
    const { data: categoriesProgression, isLoading: isLoadingCategoriesProgression, error: categoriesProgressionError } = useGetCategoriesProgression();

    const error = categoriesProgressionError;

    if (error) {
        throw error;
    }

    const isLoading = isLoadingCategoriesProgression;
    const orderedCategoriesProgression = categoriesProgression?.sort((a, b) => {
        // NB: Fundamentals should always be first
        if (a.category.isFundamentals) {
            return -1;
        } else if (b.category.isFundamentals) {
            return 1;
        } else {
            return a.category.id.localeCompare(b.category.id);
        }
    });

    if (isLoading) {
        return (
            <CommonLoading />
        );
    };

    return (
        <>
            <Container>
                {orderedCategoriesProgression?.map((category) => (
                    <Row key={category.category.id}>
                        <AchievementsCategoryList progressionForCategory={category} />
                    </Row>
                ))}
            </Container>
        </>
    );
};


