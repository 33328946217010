import Mustache from "mustache";

export class DeepLinkService {
    static replaceAppLinks = (template: string) => {
        let rendered = Mustache.render(template, microsoftAppLinks);
        // NB: perform another render pass to replace the mustache tags with the encoded versions.
        // This is necessary because the CMS Markdown Editor may encode the mustache tags.
        rendered =  Mustache.render(rendered, microsoftAppLinks, undefined, {
            tags: ['%7B%7B', '%7D%7D']
        });
        return rendered;
    }
}

const microsoftAppLinks = {
    excelLink: "https://www.microsoft365.com/launch/excel",
    formsLink: "https://forms.office.com/",
    outlookLink: "https://outlook.office.com/mail/",
    powerpointLink: "https://www.microsoft365.com/launch/powerpoint",
    teamsLink: "https://teams.microsoft.com/",
    whiteboardLink: "https://whiteboard.office.com/",
    wordLink: "https://www.microsoft365.com/launch/word",
};