import { Form, Table } from "react-bootstrap";
import { LessonReferenceModel } from "../../../hooks/lesson/LessonReferenceModel";
import { useGetLessonReferences } from "../../../hooks/lesson/UseLessons";
import { CommonLoading } from "../../loading/CommonLoading";
import { useGetLessonProgressDetails, useUpdateLessonProgress } from "../../../hooks/lesson-progression/UseLessonProgression";
import React, { useEffect, useState } from "react";
import { useGetChapters } from "../../../hooks/chapters/UseChapters";

export const DebugLessonProgress: React.FC = () => {
    const { data: lessons, isLoading: isLoadingLessons } = useGetLessonReferences();
    const { data: chapters, isLoading: isLoadingChapters } = useGetChapters();
    
    const isLoading = isLoadingLessons || isLoadingChapters;
    if (isLoading) {
        return <CommonLoading />;
    }

    if (!chapters || !lessons) {
        return (<div>
            No curriculum available yet. Please compose a user profile and curriculum first.
        </div>);
    }

    const lessonsInChapters = chapters!.flatMap((chapter) => chapter.lessons).map(lessonReference => lessons!.find(lesson => lesson.id === lessonReference.id)!);

    return (
        <div>
            <p>Lessons displayed in order of Chapters for Curriculum</p>
            <p>Check the checkbox to complete or reset lesson progress</p>
            <Table striped hover>
                <tbody>
                    {lessonsInChapters.filter(lesson => !!lesson).map((lesson: LessonReferenceModel, index: number) => (
                        <tr key={`lesson-row-${index}`}>
                            <td>{lesson.id}</td>
                            <td>{lesson.lessonName}</td>
                            <td>
                                <LessonProgressEditor lessonId={lesson.id} />
                            </td>
                        </tr>
                    )
                    )}
                </tbody>
            </Table>
        </div>
    );
};


interface ILessonProgressEditorProps {
    lessonId: string;
}

const LessonProgressEditor: React.FC<ILessonProgressEditorProps> = ({ lessonId }: ILessonProgressEditorProps) => {
    const { data: lessonProgress, isLoading } = useGetLessonProgressDetails(lessonId);
    const { mutateAsync: updateLessonProgress } = useUpdateLessonProgress();
    const [isCompleted, setIsCompleted] = useState(false);

    useEffect(() => {
        setIsCompleted(!!lessonProgress && lessonProgress.isCompleted);
    }, [lessonProgress]);

    if (isLoading) {
        return <CommonLoading />;
    }

    const onCheckChange = async (isChecked: boolean) => {
        await updateLessonProgress({
            lessonId,
            step: 0, // NB: for debug purposes ignore the step and just set the lesson as completed or not
            completed: isChecked,
        });
        setIsCompleted(isChecked);
    }

    return (
        <>
            <Form.Check type="switch" checked={isCompleted} onChange={evt => onCheckChange(evt.target.checked)} />
        </>
    );
};