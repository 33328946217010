import { useEffect } from "react";
import { CommonLoading } from "../loading/CommonLoading";
import useAnalytics from "../../hooks/analytics/UseAnalytics";
import { useGetUserIntakeProgress } from "../../hooks/user-intake-progress/useUserIntakeProgress";
import { useNavigate } from "react-router-dom";
import { AchievementsGalleryHeader } from "./AchievementsGalleryHeader";
import { AchievementsGalleryContent } from "./AchievementsGalleryContent";
import "./AchievementsGallery.css";
import { Container } from "react-bootstrap";
import { pages } from "@microsoft/teams-js";
import { AchievementsGalleryTenantStat } from "./AchievementsGalleryTenantStats";

export const AchievementsGallery: React.FC = () => {
    const { trackPageView } = useAnalytics();
    const { data: userIntakeProgress, isLoading: isUserIntakeProgressLoading, error: userIntakeProgressError, } = useGetUserIntakeProgress();
    const navigate = useNavigate();

    const isLoading = isUserIntakeProgressLoading;
    const isFirstRun = !userIntakeProgress?.isUserProfileReady || !userIntakeProgress?.isCurriculumReady;

    const error = userIntakeProgressError;

    const redirectToDashboard = async () => {
        await pages.currentApp.navigateTo({
            pageId: "dashboard",
        });
    }

    if (error) {
        throw error;
    }

    useEffect(() => {
        trackPageView("/achievements", "AchievementsGallery");
    }, []);

    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (isFirstRun) {
            redirectToDashboard();
        }
    }, [isLoading, isFirstRun]);

    if (isLoading || isFirstRun) {
        return <CommonLoading />;
    }

    return (
        <>
            <Container>
                <AchievementsGalleryHeader />
                <AchievementsGalleryContent />
            </Container>
            {/* Adds white space offset to allow full scroll of the content while the bottom bar occupies the space */}
            <Container style={{ height: '200px' }} />
            <AchievementsGalleryTenantStat />
        </>
    );
};
