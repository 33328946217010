import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";
import config from "../../config";
import { OnboardingContentModel } from "./OnboardingContentModel";
import { useLanguage } from "../language/useLanguage";

const defaultStaleTime = 5 * 60 * 1000; // 5 minutes
const remoteStorageEndpoint = config.storageContainerEndpoint;
const onboardingContentPath = "onboarding/onboarding.json";

export const useGetOnboarding = (): UseQueryResult<OnboardingContentModel, Error> => {
    const { getCurrentLanguage } = useLanguage();
    const locale = getCurrentLanguage();

    return useQuery<OnboardingContentModel, Error>({
        queryKey: ["useGetOnboarding", locale],
        queryFn: () => getOnboarding(locale),
        staleTime: defaultStaleTime,
    });
}

const getOnboarding = async (locale: string): Promise<OnboardingContentModel> => {
    const response = await axios.get(getOnboardingUrl(locale).toString());
    const onboarding = new OnboardingContentModel(response.data);
    return onboarding;
}

const getOnboardingUrl = (locale: string) => {
    return new URL(`${locale}/${onboardingContentPath}`, remoteStorageEndpoint);
}