import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { useContext } from "react";
import { TeamsFxContext } from "../../components/Context";
import { AzureFunctions } from "../../services/backend/AzureFunctions";
import { IUserLessonsProgression } from "../../services/backend/dtos/ILessonsProgression";
import { ILessonProgressMutation } from "../../services/backend/dtos/ILessonProgressMutation";
import { useGetChapters } from "../chapters/UseChapters";
import { ChapterModel } from "../chapters/ChapterModel";
import { ChapterProgress } from "./ChapterProgress";
import { LessonStateInCurriculum } from "./LessonStateInCurriculum";

const defaultStaleTime = 5 * 60 * 1000; // 5 minutes
const noStaleTime = 0; // NB: this is a workaround for react queries that process logic only and need to refetch regularly
const cacheTime = 5 * 60 * 1000;

export enum LessonProgressionQueryKeys {
    GetLessonsProgression = "useGetLessonsProgression",
    GetLessonProgressDetails = "useGetLessonProgressDetails",
    UpdateLessonProgress = "useUpdateLessonProgress",
    GetChaptersProgression = "useGetChaptersProgression",
}

export const useGetLessonsProgression = (): UseQueryResult<LessonStateInCurriculum[], Error> => {
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
    }

    const { data: chapters, isLoading } = useGetChapters();
    return useQuery<LessonStateInCurriculum[], Error>({
        queryKey: [LessonProgressionQueryKeys.GetLessonsProgression],
        queryFn: async () => {
            const lessonsProgression = await new AzureFunctions(teamsUserCredential).getLessonsProgression();
            // console.log("Lesson progression list fetched successfully!");
            return resolveLessonStateInCurriculum(lessonsProgression, chapters!);
        },
        staleTime: defaultStaleTime,
        enabled: !isLoading && !!chapters,
        gcTime: cacheTime,
    });
}

export const useGetLessonProgressDetails = (lessonId: string | null): UseQueryResult<LessonStateInCurriculum | null, Error> => {
    const { data: lessonProgression, isLoading, error } = useGetLessonsProgression();
    if (error) {
        throw error;
    }
    return useQuery<LessonStateInCurriculum | null, Error>({
        queryKey: [LessonProgressionQueryKeys.GetLessonProgressDetails, lessonId],
        queryFn: async () => {
            const progress = await getLessonProgressDetails(lessonProgression!, lessonId!);
            // console.log("Lesson progress details fetched successfully.", lessonId, progress);
            return progress;
        },
        staleTime: noStaleTime,
        enabled: !isLoading && !!lessonId && !!lessonProgression,
        gcTime: cacheTime,
    });
}

export const useUpdateLessonProgress = (): UseMutationResult<void, Error, ILessonProgressMutation> => {
    const queryClient = useQueryClient();
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
    }

    return useMutation<void, Error, ILessonProgressMutation>({
        mutationKey: [LessonProgressionQueryKeys.UpdateLessonProgress],
        mutationFn: async (lessonProgress) => {
            await new AzureFunctions(teamsUserCredential).postLessonProgress(lessonProgress);
        },
        onSuccess: async (_, variables) => {
            // NB: invalidate returning result of `LessonQueryKeys.GetLessonsProgression` or LessonQueryKeys.GetLessonProgressDetails 
            // queries so that the next time they're called, it will fetch the latest data
            await queryClient.invalidateQueries({
                queryKey: [LessonProgressionQueryKeys.GetLessonsProgression]
            });

            // NB: only invalidate the query for this lesson if just a step change is made
            if (!variables.completed) {
                await queryClient.invalidateQueries({
                    queryKey: [LessonProgressionQueryKeys.GetLessonProgressDetails, variables.lessonId]
                });
                return;
            } else {
                await queryClient.invalidateQueries({
                    queryKey: [LessonProgressionQueryKeys.GetLessonProgressDetails]
                });
            }

            await queryClient.invalidateQueries({
                queryKey: [LessonProgressionQueryKeys.GetChaptersProgression]
            });
        }
    });
}

export const useGetChaptersProgression = (): UseQueryResult<ChapterProgress[], Error> => {
    const { data: lessonProgression, isLoading: isLoadingProgression, error: progressionError } = useGetLessonsProgression();
    const { data: chapters, isLoading: isLoadingChapters, error: chapterErrors } = useGetChapters();

    const isLoading = isLoadingProgression || isLoadingChapters;

    const error = progressionError || chapterErrors;
    if (error) {
        throw error;
    }

    return useQuery<ChapterProgress[], Error>({
        queryKey: [LessonProgressionQueryKeys.GetChaptersProgression],
        queryFn: () => {
            return chapters!.map(chapter => {
                const lessonsProgression = chapter.lessons
                    .map(lesson => lessonProgression!.find(lessonProgress => lessonProgress.lessonId === lesson.id))
                    .filter(lessonProgress => !!lessonProgress)
                    .map(lessonProgress => lessonProgress!);

                return new ChapterProgress(chapter, lessonsProgression);
            });
        },
        staleTime: noStaleTime,
        enabled: !isLoading && !!lessonProgression && !!chapters,
        gcTime: cacheTime,
    });
};

const getLessonProgressDetails = (lessonStates: LessonStateInCurriculum[], lessonId: string) => {
    const lessonProgress = lessonStates.find(lesson => lesson.lessonId === lessonId);
    return Promise.resolve(lessonProgress ?? null);
}

const resolveLessonStateInCurriculum = (lessonsProgression: IUserLessonsProgression, chapters: ChapterModel[]) => {
    let isPreviousLessonFinished = true;
    const lessonState = chapters.flatMap(chapter => chapter.lessons).map((lesson, indexInCurriculum) => {
        const lessonProgress = lessonsProgression.lessons.find(lessonProgress => lessonProgress.lessonId === lesson.id);
        const isLocked = !isPreviousLessonFinished;
        const lessonState = new LessonStateInCurriculum(lesson.id, lessonProgress ?? null, isLocked, indexInCurriculum);
        isPreviousLessonFinished = !isLocked && lessonState.isCompleted;
        return lessonState;
    });

    return lessonState;
}