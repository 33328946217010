export class CoachPracticeReferenceModel {
    dto: CoachPracticeReference.CoachPracticeReferenceDto;

    constructor(dto: CoachPracticeReference.CoachPracticeReferenceDto) {
        this.dto = dto;
    }

    get id(): string {
        return this.dto.id.toString();
    }

    get version(): number {
        return this.dto.version;
    }

    get filePath(): string {
        return this.dto.filePath;
    }
}

export namespace CoachPracticeReference {
    export interface CoachPracticeReferenceDto {
        id: string;
        filePath: string;
        version: number;
    }
}
