import axios from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import config from "../../config";
import { CharacterModel } from "./CharacterModel";
import { CharacterReferenceModel } from "./CharacterReferenceModel";

const defaultStaleTime = 5 * 60 * 1000; // 5 minutes
const remoteStorageEndpoint: string = config.storageContainerEndpoint;
const charactersManifestRelativeUrl: string = "characters-manifest.json";
const charactersManifestUrl: URL = new URL(charactersManifestRelativeUrl, remoteStorageEndpoint);

export const useGetListCharacters = (): UseQueryResult<CharacterReferenceModel[], Error> => {
    return useQuery<CharacterReferenceModel[], Error>({
        queryKey: ["useGetListCharacters"],
        queryFn: listCharacterReferences,
        staleTime: defaultStaleTime,
    });
}

export const useGetCharacterDetails = (characterId: string | null | undefined): UseQueryResult<CharacterModel, Error> => {
    const { data: characters, isLoading: isLoadingCharacters } = useGetListCharacters();
    
    return useQuery<CharacterModel, Error>({
        queryKey: ["useGetCharacterDetails", characterId],
        queryFn: () => getCharacterDetails(characters!, characterId!),
        staleTime: defaultStaleTime,
        enabled: !!characterId && !isLoadingCharacters && !!characters,
    });
}

const listCharacterReferences = async (): Promise<CharacterReferenceModel[]> => {
    const response = await axios.get(charactersManifestUrl.toString());
    const characterReferences = (response.data.characterReferences as CharacterReferenceModel[]);
    return characterReferences;
}

const getCharacterDetails = async (characters: CharacterReferenceModel[], characterId: string) => {
    const characterReference = characters!.find((character) => character.id === characterId);
    if (!characterReference) {
        throw new Error(`Character ${characterId} not found`);
    }
    const characterDetailsUrl = new URL(characterReference.filePath, remoteStorageEndpoint);
    const response = await axios.get(characterDetailsUrl.toString());
    const characterDetails = response.data as CharacterModel;
    return characterDetails;
}