import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { useContext } from "react";
import { TeamsFxContext } from "../../components/Context";
import { AzureFunctions } from "../../services/backend/AzureFunctions";
import { IUserProfile } from "../../services/backend/dtos/IUserProfile";

const defaultStaleTime = 5 * 60 * 1000; // 5 minutes

enum UserProfileQueryKeys {
    GetUserProfile = "getUserProfile",
    SetUserProfile = "setUserProfile",
}

export const useGetUserProfile = (): UseQueryResult<IUserProfile, Error> => {
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
    }

    return useQuery<IUserProfile, Error>({
        queryKey: [UserProfileQueryKeys.GetUserProfile],
        queryFn: async () => {
            return await new AzureFunctions(teamsUserCredential).getUserProfile();
        },
        staleTime: defaultStaleTime,
    });
}

export const useSetUserProfile = (): UseMutationResult<void, Error, IUserProfile> => {
    const queryClient = useQueryClient();
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
    }

    return useMutation<void, Error, IUserProfile>({
        mutationKey: [UserProfileQueryKeys.SetUserProfile],
        mutationFn: async (userProfile) => {
            await new AzureFunctions(teamsUserCredential).setUserProfile(userProfile);
        },
        onSuccess: async (_, variables) => {
            await queryClient.invalidateQueries({
                queryKey: [UserProfileQueryKeys.GetUserProfile]
            });
        }
    });
}