import { Container, Row, Col, Image } from "react-bootstrap";
import { LessonCategory } from "../../hooks/lesson/LessonCategory";
import { ITenantCategoryStat } from "../../services/backend/dtos/ITenantCategoryStat";

export interface ITenantStatProps {
    category: LessonCategory;
    stat: ITenantCategoryStat;
}

export const TenantCategoryStat: React.FC<ITenantStatProps> = ({ category, stat }: ITenantStatProps) => {
    const percentageLabel = `${Math.floor(stat.averageProgressionPercentage * 100)}%`
    return (
        <Container>
            <Row>
                <Col>
                    <Image src={category.achievementsIconImageUrl} className="org-stats-category-icon" />
                </Col>
                <Col className="justify-content-left col-auto ps-0">
                    <span className="org-stats-category-percentage">{percentageLabel}</span>
                </Col>
            </Row>
        </Container>
    )
}
