import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useContext } from "react";
import { TeamsFxContext } from "../../components/Context";
import { AzureFunctions } from "../../services/backend/AzureFunctions";
import { IUserIntakeProgress } from "../../services/backend/dtos/IUserIntakeProgress";

export enum UserIntakeQueryKeys {
    UseGetUserIntakeProgress = "useGetUserIntakeProgress"
}

export const useGetUserIntakeProgress = (): UseQueryResult<IUserIntakeProgress, Error> => {
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
    }

    return useQuery<IUserIntakeProgress, Error>({
        queryKey: [UserIntakeQueryKeys.UseGetUserIntakeProgress],
        queryFn: async () => {
            const userIntakeProgress = await new AzureFunctions(teamsUserCredential).getUserIntakeProgress();
            return userIntakeProgress;
        },
        staleTime: 0,
    });
}