import { Col, Row } from "react-bootstrap";
import { LessonImagePageModel } from "../../hooks/lesson/LessonContentModel";
import LinkTransformer from "../../services/markdown/LinkTransformer";
import LessonMarkdownContent from "./markdown/LessonMarkdownContent";
import LessonPageImage from "./markdown/LessonPageImage";

interface Props {
  content: LessonImagePageModel;
}

export default function LessonImagePage(props: Props) {
  if (!!props.content.illustration?.url) {
    return (
      <div>
        <Row>
          <Col md={8} lg={8}>
            <h1>{props.content.header}</h1>
            <LessonMarkdownContent content={props.content.body} />
          </Col>
          <Col md={4} lg={4}>
            <LessonPageImage src={LinkTransformer.resolveRemoteStorageUrl(props.content.illustration.url)} />
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div>
      <h1>{props.content.header}</h1>
      <LessonMarkdownContent content={props.content.body} />
    </div>
  );
}
