import { useContext, useEffect } from "react";
import { TeamsFxContext } from "../Context";
import { AzureFunctions } from "../../services/backend/AzureFunctions";
import { useLanguage } from "../../hooks/language/useLanguage";

export const UserLocalizationInitializer: React.FC = () => {
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
    }
    const azureFunctions = new AzureFunctions(teamsUserCredential);
    const currentLocale = useLanguage().getCurrentLanguage();

    const updateUserLocalization = async () => {
        await azureFunctions.setUserLocalization({
            locale: currentLocale,
        })
    }

    useEffect(() => {
        updateUserLocalization();
    }, []);

    
    return null;
}