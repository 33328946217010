import { OnboardingProvider } from "../../../context/onboarding/OnboardingContext"
import { Onboarding } from "../../onboarding/Onboarding"
import { UserIntakeBackground } from "./UserIntakeBackground";

export const UserIntakeOnboardingPage: React.FC = () => {
    return (
        <OnboardingProvider>
            <UserIntakeBackground isBlurred={true} />
            <Onboarding />
        </OnboardingProvider>
    );
}