import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CommonButton } from "../../ui/CommonButton";
import { UserIntakeBackground } from "./UserIntakeBackground";
import { IUserIntakePageProps } from "./UserIntakeBaseCoachPage";
import "./UserIntakeWelcomePage.css";

export const UserIntakeWelcomePage: React.FC<IUserIntakePageProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <UserIntakeBackground />
            <Container className="vh-100 d-flex flex-column welcome-container" fluid>
                <Row className="welcome-row">
                    <CommonButton onClick={props.onNext}>{t("userIntake.welcome.buttonLabel")}</CommonButton>
                </Row>
            </Container >
        </>
    );
};