import { ChapterModel } from "./ChapterModel";
import { useGetLessonReferences } from "../lesson/UseLessons";
import { LessonReferenceModel } from "../lesson/LessonReferenceModel";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useContext } from "react";
import { TeamsFxContext } from "../../components/Context";
import { AzureFunctions } from "../../services/backend/AzureFunctions";

const defaultStaleTime = 5 * 60 * 1000; // 5 minutes

export const useGetChapters = (): UseQueryResult<ChapterModel[], Error> => {
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
    }

    return useQuery<ChapterModel[], Error>({
        queryKey: ["useGetListChapters"],
        queryFn: async () => {
            const curriculum = await new AzureFunctions(teamsUserCredential).getCurriculum();
            return curriculum.chapters.map(chapter => chapter as ChapterModel);
        },
        staleTime: defaultStaleTime,
    });
}

export const useGetChapterDetails = (chapterId: number): UseQueryResult<ChapterDetailsModel, Error> => {
    const { data: chapters, isLoading: isLoadingChapters, error: errorChapters } = useGetChapters();
    const { data: lessons, isLoading: isLoadingLessons, error: errorLessons } = useGetLessonReferences();

    if (errorChapters || errorLessons) {
        throw errorChapters || errorLessons;
    }

    const isLoading = isLoadingChapters || isLoadingLessons;

    return useQuery<ChapterDetailsModel, Error>({
        queryKey: ["useGetChapterDetails", chapterId],
        queryFn: () => getChapterDetails(chapters!, lessons!, chapterId),
        staleTime: defaultStaleTime,
        enabled: !isLoading && chapterId !== undefined,
    });
}

export interface ChapterDetailsModel {
    chapter: ChapterModel;
    lessonsForChapter: LessonReferenceModel[];
}


const getChapterDetails = (chapters: ChapterModel[], lessons: LessonReferenceModel[], chapterId: number): ChapterDetailsModel => {
    const chapter = chapters.find(c => c.id === chapterId);
    const lessonsForChapter = chapter!.lessons.map(lessonRef => lessons.find(l => l.id === lessonRef.id)!);
    return {
        chapter: chapter!,
        lessonsForChapter,
    };
}


export interface Curriculum {
    chapters: ChapterModel[];
}