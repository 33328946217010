import axios from 'axios';

import Config from "../../config";
import { CoachPracticeReference } from '../../hooks/lesson/LessonContentModel';
import { TeamsUserCredential } from '@microsoft/teamsfx';

class CoachConnector {
  private practiceStartPath = "/api/startPractice"
  private intakeStartPath = "/api/startIntake"

  private botEndpoint: string;
  private credential: TeamsUserCredential;

  constructor(credential: TeamsUserCredential) {
    this.botEndpoint = Config.botEndpoint;
    this.credential = credential;
  }

  async startPractice(coachPractice: CoachPracticeReference, locale: string, version: number): Promise<void> {
    const startPracticeUrl = new URL(this.practiceStartPath, this.botEndpoint).toString();

    return axios.post(startPracticeUrl, {
      coachPracticeId: coachPractice.id,
      locale: locale,
      version: version,
    }, {
      headers: await this.getHeaders()
    });
  }

  async startIntake(locale: string): Promise<void> {
    const startIntakeUrl = new URL(this.intakeStartPath, this.botEndpoint).toString();

    return axios.post(startIntakeUrl, {
      locale: locale,
    }, {
      headers: await this.getHeaders()
    });
  }

  private async getHeaders(): Promise<any> {
    return {
      Authorization: `Bearer ${await this.getToken()}`
    };
  }

  private async getToken(): Promise<string> {
    return (await this.credential.getToken(""))?.token || "";
  }
}

export default CoachConnector;