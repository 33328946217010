export interface IUserProfile {
    jobFunction: string;
    jobSector: string;
    isManager: boolean;
    tasksAndResponsibilities: string;
    experienceWithAI: Experience;
    m365Applications: { name: string; useCases: string }[];
}

export enum Experience {
    Basic = "Basic",
    Advanced = "Advanced",
};