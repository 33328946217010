import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { TeamsFxContext } from "../../components/Context";
import { AzureFunctions } from "../../services/backend/AzureFunctions";
import { IUserTenantStats } from "../../services/backend/dtos/IUserTenantStats";

const defaultStaleTime = 5 * 60 * 1000; // 5 minutes

export const useGetTenantStat = (): UseQueryResult<IUserTenantStats, Error> => {
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
    }

    return useQuery<IUserTenantStats, Error>({
        queryKey: ["getOrganizationStat"],
        queryFn: async () => {
            return await new AzureFunctions(teamsUserCredential).getUserTenantStats();
        },
        staleTime: defaultStaleTime,
    });
}