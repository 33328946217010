import { useEffect, useState } from "react";
import { Offcanvas, Tab, Tabs } from "react-bootstrap";
import { DebugLessonOverview } from "./lessons/DebugLessonOverview";
import { DebugIntakeTool } from "./tools/DebugIntakeTool";
import { DebugLessonProgress } from "./lessons/DebugLessonProgress";
import { DebugUserProfile } from "./user-profiles/DebugUserProfile";
import { DebugNotificationsTool } from "./tools/DebugNotificationsTool";
import { DebugTenantStatsTool } from "./tools/DebugTenantStatsTool";

export const DebugMenu = () => {
  const [show, setShow] = useState(false);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "*") {
      setShow(!show);
    }
  };

  const handleClose = () => setShow(false);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [show]);

  return (
    <Offcanvas show={show} onHide={handleClose} placement="top" style={{ height: "80vh" }}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Debug menu</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Tabs>
          <Tab eventKey="lessons" title="Lessons">
            <DebugLessonOverview requestClose={handleClose} />
          </Tab>
          <Tab eventKey="lessons-progression" title="Lessons Progression">
            <DebugLessonProgress />
          </Tab>
          <Tab eventKey="tools" title="Tools">
            <DebugIntakeTool />
            <DebugNotificationsTool />
            <DebugTenantStatsTool />
          </Tab>
          <Tab eventKey="user-profiles" title="User profile">
            <DebugUserProfile />
          </Tab>
        </Tabs>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
