import { Col, Container, Row, Image } from "react-bootstrap";
import "./UserIntakeBackground.css";

export interface UserIntakeBackgroundProps {
    isBlurred?: boolean;
}

export const UserIntakeBackground: React.FC<UserIntakeBackgroundProps> = ({ isBlurred }) => {
    return (
        <Container className={`intake-background ${!!isBlurred ? `intake-background-blurred` : ``}`} fluid>
            <Row className="h-100">
                <Col sm={12} md={6} className="align-self-end p-0 bg-left-panel">
                </Col>
                <Col sm={12} md={6} className="align-self-start p-0 bg-right-panel">
                </Col>
            </Row>
        </Container>
    )
}