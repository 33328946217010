import React from 'react'
import './DashboardStepper.css'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next';

export interface IDashboardStepperProps {
    steps: {
        id: number;
        label: string;
        isAvailable: boolean;
    }[];
    currentStepIndex: number;
    onStepPressed: (index: number) => void;
}

export const DashboardStepper: React.FC<IDashboardStepperProps> = ({ currentStepIndex, onStepPressed, steps }) => {
    const { t } = useTranslation();

    const getStepClassName = (index: number) => {
        const isAvailable = steps[index].isAvailable;
        const isSelected = index === currentStepIndex;
        return `${isSelected ? 'step-selected' : 'step-unselected'} ${isAvailable ? 'step-available' : ''}`;
    }

    const getStepLineClassName = (index: number) => {
        const isAvailable = steps[index].isAvailable;
        return isAvailable ? 'step-line step-line-available' : 'step-line';
    }

    const getStepLabel = (index: number, step: string) => {
        return index === currentStepIndex ? t('dashboard.stepper.currentStep', { step }) : step;
    }


    return (
        <div className='stepper'>
            {steps.map((step, index) => (
                <div key={index} className='step'>
                    {index > 0 && (
                        <div className={getStepLineClassName(index)} />
                    )}
                    <motion.button
                        className={getStepClassName(index)}
                        animate={{
                            transition: { duration: 0.5 },
                        }}
                        onClick={() => onStepPressed(index)}
                    >
                        {getStepLabel(index, step.label)}
                    </motion.button>
                </div>
            ))}
        </div>
    )
}
