import { ReactNode } from "react";

interface Props {
    children: ReactNode;
    href: string;
}

export default function LessonPageImage(props: Props) {
  return (
    <a href={props.href} target="_blank">{props.children}</a>
  );
}
