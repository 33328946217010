import { ILessonProgress } from "../../services/backend/dtos/ILessonProgress";


export class LessonStateInCurriculum {
    private _lessonId: string;
    private _lessonProgress: ILessonProgress | null;
    private _isLocked: boolean;
    private _curriculumIndex: number;

    constructor(lessonId: string, lessonProgress: ILessonProgress | null, isLocked: boolean, curriculumIndex: number) {
        this._lessonId = lessonId;
        this._lessonProgress = lessonProgress;
        this._isLocked = isLocked;
        this._curriculumIndex = curriculumIndex;
    }

    get lessonId() {
        return this._lessonId;
    }

    get isLocked() {
        return this._isLocked;
    }

    get isAvailable() {
        return !this._isLocked;
    }

    get isCompleted() {
        return !!this._lessonProgress && this._lessonProgress.completed;
    }

    get currentStep() {
        return this._lessonProgress?.step ?? 0;
    }

    get curriculumIndex() {
        return this._curriculumIndex;
    }

    get hasProgress() {
        return !!this._lessonProgress;
    }
}
