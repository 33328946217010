import Markdown from "react-markdown";
import LinkTransformer from "../../../services/markdown/LinkTransformer";
import LessonPageImage from "./LessonPageImage";
import LessonPageLink from "./LessonPageLink";
import { DeepLinkService } from "../../../services/deeplink/DeepLinkService";

interface Props {
  content: string;
}

export default function LessonMarkdownContent(props: Props) {
  const { content } = props;

  const customMarkdownComponents = {
    img(props: any) {
      return <LessonPageImage src={props.src} />;
    },
    a(props: any) {
      return <LessonPageLink href={props.href}>{props.children}</LessonPageLink>;
    }
  };

  const parseContent = (rawContent: string) => {
    return DeepLinkService.replaceAppLinks(rawContent)
      .replace(/\\\\n/gi, `&nbsp;\n`);
  }

  return (
    <Markdown
      components={customMarkdownComponents}
      urlTransform={LinkTransformer.resolveMarkdownMediaUrl}
    >
      {parseContent(content)}
    </Markdown>
  );
}