import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLessonContext } from "../../context/lesson/LessonContext";
import useAnalytics from "../../hooks/analytics/UseAnalytics";
import { useGetCharacterDetails } from "../../hooks/characters/UseCharacters";
import { BaseLessonStepModel, LessonCalendarPageModel, LessonCoachPractisePageModel, LessonImagePageModel, LessonPreviewPageModel, LessonVideoPageModel } from "../../hooks/lesson/LessonContentModel";
import LessonCalendarPage from "../lesson-pages/LessonCalendarPage";
import LessonCoachPractisePage from "../lesson-pages/LessonCoachPractisePage";
import LessonImagePage from "../lesson-pages/LessonImagePage";
import LessonPreviewPage from "../lesson-pages/LessonPreviewPage";
import LessonVideoPage from "../lesson-pages/LessonVideoPage";
import { CommonLoading } from "../loading/CommonLoading";
import StepProgressBar from "../step-progress-bar/StepProgressBar";
import Panel from "../ui/Panel";
import './Lesson.css';
import { useEffect } from "react";
import { LessonCelebration } from "../lesson-celebration/LessonCelebration";

export const Lesson = () => {
  const { t } = useTranslation();

  const { currentPage, nextPage, hasPreviousPage, previousPage, lesson: lessonContent, isLoading: isLoadingLesson, getNumberOfSteps, isCelebration } = useLessonContext();
  const { data: character, isLoading: isLoadingCharacter, error: characterError } = useGetCharacterDetails(lessonContent?.characterId ?? null)
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView("/lesson", "Lesson");
  }, []);

  // TODO - properly handle errors
  if (characterError) {
    throw characterError;
  }


  const isLoading = isLoadingCharacter || isLoadingLesson;
  if (isLoading || !lessonContent || !character) {
    return <CommonLoading />
  }
  const renderPage = (currentPage: number) => {
    const basePageContent = lessonContent?.lessonSteps[currentPage] as BaseLessonStepModel;
    switch (basePageContent.componentType) {
      case 'lesson.coach-page':
        return (<LessonCoachPractisePage content={basePageContent as LessonCoachPractisePageModel} />);
      case 'lesson.calendar-page':
        return (<LessonCalendarPage content={basePageContent as LessonCalendarPageModel} />);
      case 'lesson.video-page':
        return (<LessonVideoPage content={basePageContent as LessonVideoPageModel} />);
      case 'lesson.image-page':
      case 'lesson.page': // Required for backwards compatibility in Strapi. The first iteration of this content type was 'lesson.page' which is hard to change in the Strapi end.
        return (<LessonImagePage content={basePageContent as LessonImagePageModel} />);
      case 'lesson.preview-page':
        return (<LessonPreviewPage lesson={lessonContent!} content={basePageContent as LessonPreviewPageModel} character={character!} />);
      default:
        console.warn('[Lesson] Unknown component type, defaulting to image page.');
        return (<LessonImagePage content={basePageContent as LessonImagePageModel} />);
    }
  }

  const getNextButtonLabel = () => {
    if (currentPage === getNumberOfSteps() - 1) {
      return t('lesson.finishButton');
    }

    return t('lesson.nextButton');
  }

  return (
    <>
      <Container style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Row className="py-2" style={{ flex: '1' }}>
          <Panel className="lesson-content-panel">
            <h1 className="lesson-title">{lessonContent?.lessonName}</h1>
            <div className="lesson-content">
              {renderPage(currentPage)}
            </div>
          </Panel>
        </Row>
        <Row className="py-4 mb-4 align-items-center">
          <Col xs="auto" style={{ paddingLeft: '0px' }}>
            <Button disabled={!hasPreviousPage()} onClick={previousPage} style={{ minWidth: '180px' }}>{t('lesson.previousButton')}</Button>
          </Col>
          <Col>
            <StepProgressBar currentStep={currentPage + 1} numberOfSteps={getNumberOfSteps()} />
          </Col>
          <Col xs="auto" style={{ paddingRight: '0px' }}>
            <Button onClick={nextPage} style={{ minWidth: '180px' }}>{getNextButtonLabel()}</Button>
          </Col>
        </Row>
      </Container>
      <LessonCelebration
        show={isCelebration}
        character={character}
      />
    </>
  );
}
