import { Col, Container, Image, Row } from "react-bootstrap";
import "./AchievementsGallery.css"
import { Trans } from "react-i18next";
import { useGetTenantStat } from "../../hooks/tenant-stat/useTenantStat";
import { CommonLoading } from "../loading/CommonLoading";
export const AchievementsGalleryHeader = () => {
    return (
        <>
            <Row>
                <Col lg={8}>
                    <AchievementsGalleryTitle />
                </Col>
                <Col lg={4} className="align-content-center content-justify-right">
                    <AchievementsGalleryUserRank />
                </Col>
            </Row>
        </>
    );
};

export const AchievementsGalleryTitle: React.FC = () => {
    return (
        <Container className="header-container">
            <Row className="align-items-center">
                <Col xs="auto">
                    <Image src="achievements/achievement-gallery.png" className="header-icon" />
                </Col>
                <Col className="header-text-col">
                    <h2 className="header-title"><Trans i18nKey="achievementsGallery.title" /></h2>
                    <p className="header-subtitle"><Trans i18nKey="achievementsGallery.subtitle" /></p>
                </Col>
            </Row>
        </Container>
    )
}

export const AchievementsGalleryUserRank: React.FC = () => {
    const { data: tenantStat, isLoading, error } = useGetTenantStat();

    if (error) {
        throw error;
    }

    if (isLoading) {
        return (
            <CommonLoading />
        );
    }

    const currentRank = tenantStat?.userStat?.rankInTenant ?? 1;
    const totalRanks = tenantStat?.tenantStat?.totalUsers ?? 1;

    return (
        <Container fluid>
            <Row className="row-cols-auto d-flex align-items-end justify-content-end">
                <Col className="col-auto">
                    <span className="rank-label"><Trans i18nKey="tenantStats.rankLabel" /></span>
                </Col>
                <Col className="col-auto">
                    <span className="rank-current-value">{currentRank}/</span>
                    <span className="rank-total-value">{totalRanks}</span>
                </Col>
            </Row>
            <Row className="row-cols-auto d-flex align-items-center justify-content-end">
                <Col className="col-auto">
                    <span className="rank-description">
                        <Trans i18nKey="tenantStats.rankDescription" />
                    </span>
                </Col>
            </Row>
        </Container>
    )
}