import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IUserIntakePageProps, UserIntakeBaseCoachPage } from "./UserIntakeBaseCoachPage";
import { UserIntakeBackground } from "./UserIntakeBackground";
import { useGetCurriculumReasoning } from "../../../hooks/useCurriculumReasoning/useCurriculumReasoning";


export const UserIntakeCurriculumReasoning: React.FC<IUserIntakePageProps> = (props) => {
    const [isVirtualLoading, setIsVirtualLoading] = useState(true);
    const { isLoading: isLoadingReasoning, error: reasoningError, data: reasoning } = useGetCurriculumReasoning({});
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();
    const loadingDuration = 2_000;

    if (reasoningError) {
        throw reasoningError;
    }

    setTimeout(() => {
        setIsVirtualLoading(false);
    }, loadingDuration);

    useEffect(() => {
        setIsLoading(isVirtualLoading || isLoadingReasoning);
    }, [isVirtualLoading, isLoadingReasoning]);

    return (
        <>
            <UserIntakeBackground isBlurred />
            <UserIntakeBaseCoachPage
                header={t('userIntake.reasoning.header')}
                body={reasoning?.description ?? ""}
                buttonLabel={t('userIntake.reasoning.buttonLabel')}
                buttonLoadingLabel={t('userIntake.reasoning.buttonLoadingLabel')}
                isLoading={isLoading}
                {...props} />
        </>
    );
};
