import { Col, Image, Row } from "react-bootstrap";
import LessonMarkdownContent from "./markdown/LessonMarkdownContent";
import { LessonContentModel, LessonPreviewPageModel } from "../../hooks/lesson/LessonContentModel";
import { useTranslation } from "react-i18next";
import { CharacterModel } from "../../hooks/characters/CharacterModel";
import LinkTransformer from "../../services/markdown/LinkTransformer";
import { useGetLessonRelevanceReason } from "../../hooks/lesson-personalisation/useLessonPersonalisation";
import { CommonLoading } from "../loading/CommonLoading";

interface Props {
  lesson: LessonContentModel;
  content: LessonPreviewPageModel;
  character: CharacterModel;
}

export default function LessonPreviewPage({ lesson, content, character }: Props) {
  const { t } = useTranslation();
  const { data: lessonRelevanceReason, isLoading: isLessonRelevanceLoading, error: lessonRelevanceError } = useGetLessonRelevanceReason(lesson.id);

  if (isLessonRelevanceLoading) {
    return <CommonLoading />;
  }

  // TODO - implement proper error handling
  if (lessonRelevanceError) {
    throw lessonRelevanceError;
  }

  return (
    <div>
      <Row>
        <Col md={8} lg={8}>
          <Row>
            <h1>{t("lessonPreview.header")}</h1>
          </Row>
          <Row>
            <Col>
              <h2>{t("lessonPreview.whySection.title")}</h2>
              <p>{lessonRelevanceReason?.text}</p>
              <LessonMarkdownContent content={content.reason} />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <h2>{t("lessonPreview.whatSection.title")}</h2>
              <LessonMarkdownContent content={content.objective} />
            </Col>
          </Row>
        </Col>
        <Col md={4} lg={4}>
          <div className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
            <Image fluid src={LinkTransformer.resolveRemoteStorageUrl(character?.lesson.preview?.url)} />
          </div>
        </Col>
      </Row>
    </div>
  );
}
