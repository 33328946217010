import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";

enum LessonSessionQueryKeys {
    GetCurrentLessonSession = "useGetCurrentLessonSession",
    SetCurrentLessonSession = "useSetCurrentLessonSession",
    ClearCurrentLessonSession = "useClearCurrentLessonSession",
}

const defaultStaleTime = 5 * 60 * 1000; // 5 minutes
const localStorageKey = 'currentLessonSession';

export const useGetCurrentLessonSession = (): UseQueryResult<CurrentLessonSession, Error> => {
    return useQuery<CurrentLessonSession, Error>({
        queryKey: [LessonSessionQueryKeys.GetCurrentLessonSession],
        queryFn: async () => {
            const dataStr = localStorage.getItem(localStorageKey);
            const dto = dataStr ? JSON.parse(dataStr) : undefined;
            const currentSession = new CurrentLessonSession(dto?.lessonId);
            return currentSession;
        },
        staleTime: defaultStaleTime,
    });
}

export const useSetCurrentLessonSession = (): UseMutationResult<void, Error, ICurrentLessonSession> => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, ICurrentLessonSession>({
        mutationKey: [LessonSessionQueryKeys.SetCurrentLessonSession],
        mutationFn: async (lessonSession) => {
            const dataStr = JSON.stringify(lessonSession as ICurrentLessonSession);
            localStorage.setItem(localStorageKey, dataStr);
        },
        onSuccess: async (_, __) => {
            await queryClient.invalidateQueries({
                queryKey: [LessonSessionQueryKeys.GetCurrentLessonSession],
            });
        },
    });
}

export const useClearCurrentLessonSession = (): UseMutationResult<void, Error, void> => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, void>({
        mutationKey: [LessonSessionQueryKeys.SetCurrentLessonSession],
        mutationFn: async () => {
            localStorage.removeItem(localStorageKey);
        },
        onSuccess: async (_, __) => {
            await queryClient.invalidateQueries({
                queryKey: [LessonSessionQueryKeys.GetCurrentLessonSession],
            });
        },
    });
}

interface ICurrentLessonSession {
    lessonId: string;
}

export class CurrentLessonSession implements ICurrentLessonSession {
    lessonId: string;

    constructor(lessonId: string) {
        this.lessonId = lessonId;
    }

    hasSession() {
        return !!this.lessonId;
    }
}