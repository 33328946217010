import { Button, ButtonProps, Spinner } from "react-bootstrap";
import './CommonButton.css';

export type CommonButtonProps = ButtonProps & {
    children: React.ReactNode;
    isAttractEnabled?: boolean;
    isLoading?: boolean;
    buttonLoadingLabel?: string;
}

export const CommonButton: React.FC<CommonButtonProps> = ({ children, isLoading, buttonLoadingLabel, isAttractEnabled, ...props }) => {
    const getClassName = () => {
        if (!!isAttractEnabled) {
            return "common-button attract";
        } else {
            return "common-button";
        }
    }

    return (
        <>
            <Button className={getClassName()} {...props}>
                {!isLoading && children}
                {!!isLoading && (
                    <>
                        <Spinner />
                        {buttonLoadingLabel}
                    </>
                )}
            </Button>
        </>
    )
}