import { Trans, useTranslation } from "react-i18next";
import Panel from "../ui/Panel";
import { Col, Container, Image, Row } from "react-bootstrap";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import { CharacterModel } from "../../hooks/characters/CharacterModel";
import "./LessonCelebration.css";
import LinkTransformer from "../../services/markdown/LinkTransformer";
import { CommonButton } from "../ui/CommonButton";
import { app, pages } from "@microsoft/teams-js";
import { useNavigate } from "react-router-dom";

export interface INoticePopupProps {
    show?: boolean;
    character: CharacterModel;
}

export const LessonCelebration = (props: INoticePopupProps) => {
    const { show, character } = props;

    const { t } = useTranslation();
    const characterCompletedImgUrl = LinkTransformer.resolveRemoteStorageUrl(character.lesson.completed.url);
    const navigate = useNavigate();

    const navigateToDashboard = async () => {
        navigate("/dashboard");
    }

    const navigateToAchievements = async () => {
        await pages.currentApp.navigateTo({
            pageId: "achievements",
        });
    }

    return ReactDOM.createPortal(
        (<motion.div animate={{ opacity: show ? 1 : 0 }} transition={{ duration: .45 }} >
            <div className={`celebration-popup ${!show && 'hide'} ${show && 'show'}`}>
                <Container className="celebration-popup-container" fluid="lg">
                    <Panel className="celebration-popup-panel">
                        <Container className="container-fluid full-height d-flex flex-column" >
                            <Row className="flex-grow-1">
                                <Col>
                                    <h1 className="celebration-header">
                                        <Trans i18nKey="lessonCelebration.header" />
                                    </h1>
                                    <p className="celebration-body">
                                        <Trans i18nKey="lessonCelebration.body" />
                                    </p>
                                </Col>
                                <Col>
                                    <Container className="celebration-character-container">
                                        <Image src="lesson/lesson-celebration-beams.png" className="celebration-character-beams" />
                                        <Image src="lesson/lesson-celebration-achievement.png" className="celebration-character-achievement" />
                                        <Image src={characterCompletedImgUrl} className="celebration-character-image" />
                                    </Container>
                                </Col>
                            </Row>
                            <Row className="mt-auto justify-content-center" style={{ zIndex: 1 }}>
                                <Col className="d-flex justify-content-center">
                                    <CommonButton onClick={navigateToDashboard}>
                                        <Trans i18nKey="lessonCelebration.dashboardButton" />
                                    </CommonButton>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <CommonButton onClick={navigateToAchievements}>
                                        <Trans i18nKey="lessonCelebration.achievementsButton" />
                                    </CommonButton>
                                </Col>
                            </Row>
                        </Container>
                    </Panel>
                </Container>
            </div>
        </motion.div>),
        document.body,
    );
}
