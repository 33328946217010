import { useEffect, useState } from "react";
import { CommonLoading } from "../../loading/CommonLoading";
import { Experience, IUserProfile } from "../../../services/backend/dtos/IUserProfile";
import { useGetUserProfile, useSetUserProfile } from "../../../hooks/user-profile/UseUserProfile";
import { Stack } from "react-bootstrap";

interface IDebugUserProfileProps {

}

export const DebugUserProfile = (props: IDebugUserProfileProps) => {
    const { isLoading, data: userProfile } = useGetUserProfile();
    const { mutateAsync: setUserProfile } = useSetUserProfile();

    if (isLoading) {
        return <CommonLoading />;
    }

    return (
        <div>
            <div className="pt-4">
                <h4>Load a user profile</h4>
                <Stack direction="horizontal" gap={4}>
                    <button onClick={async () => await setUserProfile(gameDeveloperUserProfile)}>Game developer in Gaming</button>
                    <button onClick={async () => await setUserProfile(projectManagerUserProfile)}>Project manager in Finance</button>
                    <button onClick={async () => await setUserProfile(ceoUserProfile)}>CEO in Healthcare</button>
                </Stack>
            </div>
            <div className="pt-4">
                <h4>Current user profile</h4>
                <pre>{JSON.stringify(userProfile || {}, null, 2)}</pre>
            </div>
        </div>
    );
};

const gameDeveloperUserProfile = {
    jobFunction: 'Game Developer',
    jobSector: 'Gaming',
    isManager: false,
    tasksAndResponsibilities: 'Creating code for games',
    experienceWithAI: Experience.Advanced,
    m365Applications: [
        {
            name: 'Word',
            useCases: 'Creating documentation',
        },
        {
            name: 'Excel',
            useCases: 'Creating datasheets that the game can process',
        }
    ],
};

const projectManagerUserProfile = {
    jobFunction: 'Project Manager',
    jobSector: 'Finance',
    isManager: true,
    tasksAndResponsibilities: 'Overseeing financial projects, ensuring timely delivery, managing budgets, coordinating with stakeholders, and ensuring compliance with financial regulations.',
    experienceWithAI: Experience.Advanced,
    m365Applications: [
        {
            name: 'Excel',
            useCases: 'Budget management, financial analysis, and project tracking',
        }
    ],
};

const ceoUserProfile = {
    jobFunction: 'CEO',
    jobSector: 'Healthcare',
    isManager: true,
    tasksAndResponsibilities: 'Providing strategic direction, overseeing company operations, ensuring financial health, building relationships with stakeholders, and driving organizational growth.',
    experienceWithAI: Experience.Basic,
    m365Applications: [
        {
            name: "Word",
            useCases: "drafting communications and policy documents"
        },
        {
            name: "PowerPoint",
            useCases: "creating presentations for board meetings and investor pitches"
        },
        {
            name: "Excel",
            useCases: "analyzing financial data and tracking company performance"
        },
        {
            name: "Teams",
            useCases: "collaborating with executive team and conducting virtual meetings"
        },
        {
            name: "Outlook",
            useCases: "managing emails and scheduling meetings"
        }
    ],
};