class Config {
  get initiateLoginEndpoint(): string {
    return process.env.REACT_APP_START_LOGIN_PAGE_URL || '';
  }

  get clientId(): string {
    return process.env.REACT_APP_CLIENT_ID || '';
  }

  get apiEndpoint(): string {
    return this.withTrailingSlash(process.env.REACT_APP_FUNC_ENDPOINT || '');
  }

  get storageContainerEndpoint(): string {
    return this.withTrailingSlash(process.env.REACT_APP_STORAGE_CONTAINER_ENDPOINT || '');
  }

  get botEndpoint(): string {
    return this.withTrailingSlash(process.env.REACT_APP_COACH_BOT_ENDPOINT || '');
  }

  get botId(): string {
    return process.env.REACT_APP_COACH_BOT_ID || '';
  }

  get demoEnabled(): boolean {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.has('demo') && searchParams.get('demo') === '1';
  }

  get debugEnabled(): boolean {
    return process.env.REACT_APP_DEBUG_ENABLED?.toLowerCase() === 'true';
  }

  get developerDebugEnabled(): boolean {
    return process.env.REACT_APP_DEVELOPER_DEBUG_ENABLED?.toLowerCase() === 'true';
  }

  get firstTimeRunFormUrl(): string {
    return "https://forms.office.com/Pages/ResponsePage.aspx?id=uQMBK0z1QEOFE-HEI7EDdy3AQ0V5neROqrihqS4kV4ZUOTdLMlFNU1pMMVlDNEQ3VjZPVE1SSjk1Mi4u&embed=true";
  }

  get googleAnalyticsMeasurementId(): string {
    return process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || '';
  }

  get appInsightsConnectionString(): string {
    return process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING || '';
  }

  get appInsightsEnabled(): boolean {
    return process.env.REACT_APP_APP_INSIGHTS_ENABLED?.toLowerCase() === 'true';
  }

  get appEnvironment(): string {
    return process.env.REACT_APP_APP_ENV || 'unknown';
  }

  get analyticsVersion(): number {
    return 1; // Bump this number when doing backwards incompatible changes to the analytics.
  }

  get fallbackLocale(): string {
    return localeConfig.fallback;
  }

  get supportedLocales(): string[] {
    return localeConfig.supported;
  }

  private withTrailingSlash(url: string): string {
    return url.endsWith('/') ? url : `${url}/`;
  }
}

const localeConfig = {
  fallback: 'en',
  supported: ['en', 'nl'],
};

const config = new Config();
export default config;