import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'video.js/dist/video-js.css';
import "./index.css";
import './i18n';

import { createRoot } from "react-dom/client";
import { App } from './components/App';

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
