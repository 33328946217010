import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAnalytics from "../../hooks/analytics/UseAnalytics";
import { useGetChaptersProgression, useGetLessonProgressDetails, useUpdateLessonProgress } from "../../hooks/lesson-progression/UseLessonProgression";
import { LessonStateInCurriculum } from "../../hooks/lesson-progression/LessonStateInCurriculum";
import { LessonContentModel } from "../../hooks/lesson/LessonContentModel";
import { useGetLessonDetails } from "../../hooks/lesson/UseLessons";
import { useClearCurrentLessonSession, useSetCurrentLessonSession } from "../../hooks/lesson-session/UseLessonSession";

interface LessonContextType {
  currentPage: number;
  setCurrentPage: (step: number) => void;
  hasNextPage: () => boolean;
  nextPage: () => void;
  hasPreviousPage: () => boolean;
  previousPage: () => void;
  isLoading: boolean;
  lesson?: LessonContentModel;
  lessonProgress: LessonStateInCurriculum | null | undefined;
  getNumberOfSteps: () => number;
  isCelebration: boolean;
}

interface Props {
  children: React.ReactNode;
}

const LessonContext = createContext<LessonContextType | undefined>(undefined);

export interface ILessonLocationState {
  initialStep?: number;
}

export const LessonProvider: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { mutateAsync: setCurrentLessonSession } = useSetCurrentLessonSession();
  const { mutateAsync: clearCurrentLessonSession } = useClearCurrentLessonSession();

  const locationState = location.state as ILessonLocationState;
  const initialStep = locationState?.initialStep ?? 0;
  const { lessonId } = useParams();

  const [currentPage, setCurrentPage] = useState(initialStep);
  const [isCelebration, setIsCelebration] = useState(false);

  const { data: lesson, isLoading: isLessonLoading, error: lessonError } = useGetLessonDetails(lessonId!);

  const { mutateAsync: updateLessonProgress } = useUpdateLessonProgress();
  const { data: lessonProgress, error: lessonProgressError, isLoading: isLessonProgressLoading } = useGetLessonProgressDetails(lessonId!);
  const { data: chaptersProgression, isLoading: isChaptersProgressionLoading, error: chaptersProgressionError } = useGetChaptersProgression();
  const { trackLessonFinished, trackLessonViewed, trackChapterFinished } = useAnalytics();

  const isLoading = isLessonLoading || isLessonProgressLoading || isChaptersProgressionLoading || !lesson;

  const progressExists = !!lessonProgress?.hasProgress;

  // TODO - properly handle error
  const error = lessonError || lessonProgressError || chaptersProgressionError;
  if (error) {
    throw error;
  }

  const hasNextPage = () => {
    return currentPage < getNumberOfSteps()! - 1;
  }

  const nextPage = async () => {
    if (!hasNextPage()) {
      trackLessonFinished({
        id: lesson?.id || 'unknown',
        type: lesson?.lessonType || 'unknown',
        aiComplexity: lesson?.aiComplexity || 'unknown',
        isMandatory: lesson?.isMandatory,
        isPriority: lesson?.isPriority,
        m365apps: lesson?.m365Apps || [],
        wasFinished: lessonProgress?.isCompleted || false,
        curriculumIndex: (lessonProgress?.curriculumIndex === undefined || lessonProgress?.curriculumIndex === null) ? -1 : lessonProgress?.curriculumIndex,
      });

      const chapterIndexContainingThisLesson = chaptersProgression?.findIndex((chapterProgression) => chapterProgression.chapter.lessons.some((lesson) => lesson.id === lessonId));
      const chapterContainingThisLesson = chaptersProgression?.find((chapterProgression) => chapterProgression.chapter.lessons.some((lesson) => lesson.id === lessonId));
      const lessonsInThisChapter = chapterContainingThisLesson?.chapter.lessons;
      const isLastLessonInChapter = lessonsInThisChapter?.at(-1)?.id === lessonId;
      if (isLastLessonInChapter) {
        trackChapterFinished({
          id: chapterContainingThisLesson!.chapter.id,
          index: chapterIndexContainingThisLesson!,
          lessonIds: lessonsInThisChapter?.map((lesson) => lesson.id) || [],
        });
      }

      await saveLessonFinish();
      await clearCurrentLessonSession();
      setIsCelebration(true);
      return;
    }

    setCurrentPage((prev) => prev + 1);
  }

  const hasPreviousPage = () => {
    return currentPage > 0;
  }

  const previousPage = () => {
    setCurrentPage((prev) => prev - 1);
  }

  const getNumberOfSteps = () => {
    return lesson?.lessonSteps.length || 0;
  }

  const saveLessonStepProgress = async () => {
    const isPreviouslyCompleted = !!lessonProgress && lessonProgress.isCompleted;
    await updateLessonProgress({ lessonId: lesson!.id, step: currentPage, completed: isPreviouslyCompleted });
    // NB: auto-save notification toast could be shown here...
  }

  const saveLessonFinish = async () => {
    // NB: reset current step to 0 to allow users to redo the lesson and not keep a session state
    await updateLessonProgress({ lessonId: lesson!.id, step: 0, completed: true });
  }

  const context: LessonContextType = {
    currentPage,
    setCurrentPage,
    hasNextPage,
    nextPage,
    hasPreviousPage,
    previousPage,
    isLoading,
    lesson,
    lessonProgress,
    getNumberOfSteps,
    isCelebration,
  };

  useEffect(() => {
    if (lesson === undefined || isLoading) {
      return;
    }

    setCurrentLessonSession({ lessonId: lesson!.id })

    trackLessonViewed({
      id: lesson?.id || 'unknown',
      type: lesson?.lessonType || 'unknown',
      aiComplexity: lesson?.aiComplexity || 'unknown',
      isMandatory: lesson?.isMandatory,
      isPriority: lesson?.isPriority,
      m365apps: lesson?.m365Apps || [],
      wasInProgress: progressExists,
      wasFinished: lessonProgress?.isCompleted || false,
      curriculumIndex: (lessonProgress?.curriculumIndex === undefined || lessonProgress?.curriculumIndex === null) ? -1 : lessonProgress?.curriculumIndex,
    });

  }, [lesson]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    saveLessonStepProgress();
  }, [currentPage]);

  return (
    <LessonContext.Provider value={context}>
      {children}
    </LessonContext.Provider>
  );
}

export const useLessonContext = () => {
  const context = useContext(LessonContext);
  if (context === undefined) {
    throw new Error('useLessonContext must be used within a LessonProvider');
  }

  return context;
}