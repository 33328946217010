import { Image } from "react-bootstrap";

interface Props {
    src: string;
}

export default function LessonPageImage(props: Props) {
  return (
    <Image fluid src={props.src} />
  );
}
