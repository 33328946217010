import { useEffect, useState } from "react";
import { useGetCurrentLessonSession } from "../lesson-session/UseLessonSession";
import { useGetLessonProgressDetails } from "../lesson-progression/UseLessonProgression";
import { useGetLessonDetails } from "../lesson/UseLessons";
import { ILessonLocationState } from "../../context/lesson/LessonContext";
import { useNavigate } from "react-router-dom";

interface IResumeLesson {
    isLoading: boolean;
    hasLessonSession: boolean;
    resumeLesson: () => void;
    error?: Error;
}

export const useResumeLesson = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [hasLessonSession, setHasLessonSession] = useState(false);
    const { data: currentLessonSession, isLoading: isCurrentLessonSessionLoading, error: currentLessonSessionError } = useGetCurrentLessonSession();
    const { data: lessonProgress, isLoading: isLessonProgressLoading, error: lessonProgressError } = useGetLessonProgressDetails(currentLessonSession?.lessonId ?? null);
    const { data: lesson, isLoading: isLessonsLoading, error: lessonError } = useGetLessonDetails(currentLessonSession?.lessonId);

    const resumeLesson = () => {
        const lessonLocationState: ILessonLocationState = {
            initialStep: lessonProgress?.currentStep ?? 0,
        };
        navigate(`/lesson/${currentLessonSession!.lessonId}`, {
            state: lessonLocationState
        });
    }

    useEffect(() => {
        const isLoading = isCurrentLessonSessionLoading || isLessonProgressLoading || isLessonsLoading;
        setLoading(isLoading);
    }, [isCurrentLessonSessionLoading, isLessonProgressLoading, isLessonsLoading]);

    useEffect(() => {
        setHasLessonSession(!!lessonProgress && !!lesson && !!currentLessonSession && currentLessonSession.hasSession() && !lessonError);
    }, [currentLessonSession, lesson, lessonProgress]);


    const error = currentLessonSessionError || lessonProgressError;
    if (error) {
        setLoading(false);
        setHasLessonSession(false);
        return {
            isLoading,
            hasLessonSession,
            resumeLesson,
            error,
        }
    }

    const result: IResumeLesson = {
        isLoading,
        hasLessonSession,
        resumeLesson,
        error: undefined,
    }
    return result;
};