import { useTranslation } from "react-i18next";
import Panel from "../ui/Panel";
import { Button, Col, Container } from "react-bootstrap";
import './NoticePopup.css';
import React from "react";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";

export interface INoticePopupProps {
    headerTranslationKey: string;
    bodyTranslationKey: string;
    isBlocking?: boolean;
    show?: boolean;
}

export const NoticePopup = (props: INoticePopupProps) => {
    const { headerTranslationKey, bodyTranslationKey, isBlocking, show } = props;

    const { t } = useTranslation();

    return ReactDOM.createPortal(
        (<motion.div animate={{ opacity: show ? 1 : 0 }} transition={{ duration: .45 }} >
            <div className={`notice-popup ${!show && 'hide'} ${show && 'show'}`}>
                <Container>
                    <Col lg={7} md={12} className="notice-popup-container">
                        <Panel className="notice-popup-panel">
                            <h2>{t(headerTranslationKey)}</h2>
                            <p>
                                {t(bodyTranslationKey)}
                            </p>
                            <div className="notice-popup-buttons">
                                {!isBlocking && <Button>{t('chatRedirection.closeButton')}</Button>}
                            </div>
                        </Panel>
                    </Col>
                </Container>
            </div>
        </motion.div>),
        document.body,
    );
}
