import { useDashboardContext } from "../../../context/dashboard/DashboardContext";
import { DashboardStepper } from "./DashboardStepper";
import { useGetChapters } from "../../../hooks/chapters/UseChapters";
import { CommonLoading } from "../../loading/CommonLoading";
import { useGetChaptersProgression } from "../../../hooks/lesson-progression/UseLessonProgression";
import { ChapterModel } from "../../../hooks/chapters/ChapterModel";

export const DashboardStepperContainer = () => {
    const { data: chapters, isLoading: isLoadingChapters, error: chaptersError } = useGetChapters();
    const { data: chaptersProgression, isLoading: isChaptersProgressionLoading, error: chaptersProgressionError } = useGetChaptersProgression();
    const { currentChapterId, setCurrentChapterId, isLoading: isDashboardContextLoading } = useDashboardContext();

    const isLoading = isDashboardContextLoading || isLoadingChapters || isChaptersProgressionLoading || !chaptersProgression;
    if (isLoading) {
        return <CommonLoading />;
    }

    // TODO - handle error properly
    const error = chaptersError || chaptersProgressionError;
    if (error) {
        throw error;
    }

    const isChapterAvailable = (chapter: ChapterModel) => {
        const chapterProgress = chaptersProgression!.find(chapterProgress => chapterProgress.chapter.id === chapter.id);
        return !!chapterProgress && chapterProgress.isAvailable;
    }

    const steps = chapters!.map((chapter, index) => ({
        id: chapter.id,
        label: (index + 1).toString(),
        isAvailable: isChapterAvailable(chapter),
    }));

    const currentStepIndex = chapters!.findIndex(chapter => chapter.id === currentChapterId);

    const onStepPressed = (index: number) => {
        const chapterId = chapters![index].id;
        setCurrentChapterId(chapterId);
    }

    const props = {
        steps,
        currentStepIndex,
        onStepPressed,
    }

    return (
        <div>
            <DashboardStepper {...props} />
        </div>
    )
}
