import { Image } from "react-bootstrap";
import "./LessonCard.css";
import { useTranslation } from "react-i18next";
import { LessonReferenceModel } from "../../hooks/lesson/LessonReferenceModel";
import { useNavigate } from "react-router-dom";
import { useGetCharacterDetails } from "../../hooks/characters/UseCharacters";
import LinkTransformer from "../../services/markdown/LinkTransformer";
import { CommonLoading } from "../loading/CommonLoading";
import { useGetLessonProgressDetails } from "../../hooks/lesson-progression/UseLessonProgression";
import { LessonStateInCurriculum } from "../../hooks/lesson-progression/LessonStateInCurriculum";
import { ILessonLocationState } from "../../context/lesson/LessonContext";

export interface ILessonCardProps {
    lesson: LessonReferenceModel;
}

export enum LessonState {
    Locked,
    Available,
    Continue,
    Finished
}

export const LessonCard = ({ lesson }: ILessonCardProps) => {
    const { t } = useTranslation();
    const { data: character, isLoading: isLoadingCharacter, error: characterError } = useGetCharacterDetails(lesson.characterId);
    const { data: lessonProgress, isLoading: isLoadingLessonProgress, error: lessonProgressError } = useGetLessonProgressDetails(lesson.id);
    const navigate = useNavigate();

    const isLoading = isLoadingCharacter || isLoadingLessonProgress;
    if (isLoading || !lessonProgress) {
        return (
            <div className="col-12 col-md-6 mx-4 mb-4 lesson-card-container">
                <div className="lesson-card lesson-card-locked">
                    <CommonLoading />
                </div>
            </div>
        );
    }

    // TODO - implement proper error handling
    const error = characterError || lessonProgressError;
    if (error) {
        throw error;
    }

    const getLessonStateFromProgress = (lessonProgress: LessonStateInCurriculum) => {
        if (lessonProgress.isCompleted) {
            return LessonState.Finished;
        }

        if (lessonProgress.isLocked) {
            return LessonState.Locked;
        }

        if (lessonProgress.currentStep > 0) {
            return LessonState.Continue;
        }

        return LessonState.Available;
    }

    const state = getLessonStateFromProgress(lessonProgress!);

    const getCardClassName = () => {
        switch (state) {
            case LessonState.Available:
            case LessonState.Continue:
                return "lesson-card lesson-card-available";
            case LessonState.Finished:
                return "lesson-card lesson-card-finished";
            case LessonState.Locked:
            default:
                return "lesson-card lesson-card-locked";
        }
    };

    const getLessonButtonLabel = () => {
        switch (state) {
            case LessonState.Available:
                return t('dashboard.lessonStart');
            case LessonState.Continue:
                return t('dashboard.lessonContinue');
            case LessonState.Finished:
                return t('dashboard.lessonRedo');
            case LessonState.Locked:
            default:
                return t('dashboard.lessonLocked');
        }
    }

    const getLessonButtonClassName = () => {
        switch (state) {
            case LessonState.Available:
                return "lesson-button-available";
            case LessonState.Continue:
                return "lesson-button-continue";
            case LessonState.Finished:
                return "lesson-button-finished";
            case LessonState.Locked:
            default:
                return "lesson-button-locked";
        }
    }

    const getLessonCharacterImage = () => {
        if (!character) {
            return "";
        }

        switch (state) {
            case LessonState.Finished:
                return LinkTransformer.resolveRemoteStorageUrl(character?.dashboard?.completed?.url);
            default:
                return LinkTransformer.resolveRemoteStorageUrl(character?.dashboard?.notStarted?.url);
        }
    }

    const getLessonCharacterBackground = () => {
        switch (state) {
            case LessonState.Finished:
                return <Image src="dashboard/lesson-bg-completed.png" className="lesson-bg-completed" />;
            case LessonState.Available:
            case LessonState.Continue:
                return <Image src="dashboard/lesson-bg-available.png" className="lesson-bg-available" />;
            case LessonState.Locked:
            default:
                return <Image src="dashboard/lesson-bg-locked.png" className="lesson-bg-locked" />;
        }
    }

    const startLesson = () => {
        const lessonLocationState: ILessonLocationState = {
            initialStep: !lessonProgress ? 0 : lessonProgress.currentStep
        }


        navigate(`/lesson/${lesson.id}`, {
            state: lessonLocationState
        });
    }

    return (
        <>
            <div className="col-12 col-md-6 mx-4 mb-4 lesson-card-container">
                <div className={getCardClassName()}>
                    {getLessonCharacterBackground()}
                    <Image src={getLessonCharacterImage()} className="card-logo" />
                    <span>{lesson.lessonName}</span>
                    <button
                        className={getLessonButtonClassName()}
                        onClick={startLesson}>
                        {getLessonButtonLabel()}
                    </button>
                </div>
                {state === LessonState.Finished && (
                    <div className="lesson-completed-badge">
                        <Image src="dashboard/lesson-completed-badge.png" />
                    </div>
                )}
            </div>
        </>
    );
};