import { useTranslation } from 'react-i18next';
import './StepProgressBar.css';

interface Props {
    className?: string;
    numberOfSteps: number;
    currentStep: number;
}

export default function StepProgressBar(props: Props) {  
  const { t } = useTranslation();

  return (
    <div className={`stepProgressBar ${props.className}`}>
      <h5>
        { t('stepProgressBar.step', { currentStep: props.currentStep, numberOfSteps: props.numberOfSteps }) }
      </h5>
    </div>
  );
}
