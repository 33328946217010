import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { TeamsFxContext } from "../../components/Context";
import { AzureFunctions } from "../../services/backend/AzureFunctions";
import { ICurriculumReasoning } from "../../services/backend/dtos/ICurriculumReasoning";
import { useLanguage } from "../language/useLanguage";
const defaultStaleTime = 5 * 60 * 1000; // 5 minutes

export interface IUseGetCurriculumReasoningProps {
    enabled?: boolean;
}

export const useGetCurriculumReasoning = ({ enabled = true }: IUseGetCurriculumReasoningProps): UseQueryResult<ICurriculumReasoning, Error> => {
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    const locale = useLanguage().getCurrentLanguage();
    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
    }

    return useQuery<ICurriculumReasoning, Error>({
        queryKey: ["useGetCurriculumReasoning"],
        queryFn: async () => {
            const reasoning = await new AzureFunctions(teamsUserCredential).getCurriculumReasoning(locale);
            return reasoning;
        },
        staleTime: defaultStaleTime,
        enabled: enabled,
    });
}