import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useGetLessonReferences } from "../lesson/UseLessons";
import { useGetLessonsProgression } from "./UseLessonProgression";
import { LessonReferenceModel } from "../lesson/LessonReferenceModel";
import { LessonCategory } from "../lesson/LessonCategory";
import { LessonStateInCurriculum } from "./LessonStateInCurriculum";


export interface ICategoryProgress {
    category: LessonCategory;
    completed: number;
    total: number;
    lessons: LessonStateInCurriculum[];
}

export enum ProgressPerCategoryQueryKeys {
    UseProgressPerCategory = "useProgressPerCategory"
}

const defaultStaleTime = 5 * 60 * 1000; // 5 minutes
const cacheTime = 5 * 60 * 1000;

export const useGetCategoriesProgression = (): UseQueryResult<ICategoryProgress[], Error> => {
    const { data: lessonReferences, isLoading: isLessonReferencesLoading, error: lessonReferencesError } = useGetLessonReferences();
    const { data: lessonsProgression, isLoading: isLessonsProgressionLoading, error: lessonsProgressionError } = useGetLessonsProgression();

    const isLoading = isLessonReferencesLoading || isLessonsProgressionLoading;
    const error = lessonReferencesError || lessonsProgressionError;

    return useQuery<ICategoryProgress[], Error>({
        queryKey: [ProgressPerCategoryQueryKeys.UseProgressPerCategory],
        queryFn: () => {
            if (error) {
                throw error;
            }
            return getLessonsPerCategory(lessonReferences!, lessonsProgression!);
        },
        staleTime: defaultStaleTime,
        enabled: !isLoading && !!lessonReferences && !!lessonsProgression,
        gcTime: cacheTime,
    });
} 

const getLessonsPerCategory = (lessonReferences: LessonReferenceModel[], lessonsProgression: LessonStateInCurriculum[]): ICategoryProgress[] => {
    const lessonReferencesPerCategory = lessonsProgression.reduce((map, progress) => {
        const lesson = lessonReferences.find(lesson => lesson.id === progress.lessonId)!;
        const categoryId = lesson.lessonCategory.id;
        map[categoryId] ??= [];
        map[categoryId].push(progress);
        return map;
    }, {} as Record<string, LessonStateInCurriculum[]>);

    const categoryProgressArray = Object.entries(lessonReferencesPerCategory).map(([categoryId, categoryProgression]) => {
        const completed = categoryProgression.filter(progress => progress.isCompleted).length;
        return {
            category: new LessonCategory(categoryId),
            completed,
            total: categoryProgression.length,
            lessons: categoryProgression,
        } as ICategoryProgress;
    });

    return categoryProgressArray;
}