import { Col, Container, Row } from "react-bootstrap";
import './UserIntakeBaseCoachPage.css'
import Panel from "../../ui/Panel";
import { CommonButton } from "../../ui/CommonButton";
import { CoachImage } from "../../ui/CoachImage";

export interface IUserIntakeCoachMessageProps {
    header: string
    body: string;
    buttonLabel: string;
    buttonLoadingLabel?: string;
    isLoading?: boolean;
    onNext: () => void;
}

export interface IUserIntakePageProps {
    onNext: () => void;
}

export const UserIntakeBaseCoachPage: React.FC<IUserIntakeCoachMessageProps> = ({ header, body, buttonLabel, buttonLoadingLabel, onNext, isLoading = false }) => {
    return (
        <Container>
            <Row className="align-items-center">
                <Panel className="coach-page-panel">
                    <Container fluid>
                        <Row className="justify-content-md-center">
                            <Col sm={12} md={5}>
                                <div className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
                                    <CoachImage fluid src="/intake/coach-intake.png" />
                                </div>
                            </Col>
                            <Col>
                                <h1>{header}</h1>
                                <p className="coach-page-text">{body}</p>
                            </Col>
                        </Row>
                    </Container>
                </Panel>
            </Row>
            <Row style={{ justifyContent: "right" }}>
                <CommonButton onClick={onNext} disabled={isLoading} isAttractEnabled={isLoading} isLoading={isLoading} buttonLoadingLabel={buttonLoadingLabel}>
                    {buttonLabel}
                </CommonButton>
            </Row>
        </Container>
    );
}