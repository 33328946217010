import { Col, Row } from "react-bootstrap";
import { LessonVideoPageModel } from "../../hooks/lesson/LessonContentModel";
import { VideoPlayer } from "../video/VideoPlayer";
import LessonMarkdownContent from "./markdown/LessonMarkdownContent";
import LinkTransformer from "../../services/markdown/LinkTransformer";
import { useLanguage } from "../../hooks/language/useLanguage";

interface Props {
  content: LessonVideoPageModel;
}

export default function LessonVideoPage(props: Props) {
  const { header, body, video, subtitles } = props.content;
  const { getCurrentLanguage } = useLanguage();
  const locale = getCurrentLanguage();

  return (
    <div>
      <Row>
        <Col md={12} lg={6}>
          <h1>{header}</h1>
          <LessonMarkdownContent content={body} />
        </Col>
        <Col md={12} lg={6}>
          <VideoPlayer
            id="lesson-video-page"
            className="vjs-16-9"
            src={LinkTransformer.resolveRemoteStorageUrl(video?.url)}
            subtitles={subtitles && {
              url: LinkTransformer.resolveRemoteStorageUrl(subtitles.url),
              srcLang: locale,
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
