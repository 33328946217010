import { ChapterModel } from "../chapters/ChapterModel";
import { LessonStateInCurriculum } from "./LessonStateInCurriculum";


export class ChapterProgress {
    private _chapter: ChapterModel;
    private _lessonsProgression: LessonStateInCurriculum[];

    constructor(chapter: ChapterModel, lessonsProgression: LessonStateInCurriculum[]) {
        this._chapter = chapter;
        this._lessonsProgression = lessonsProgression;
    }

    public get chapter() {
        return this._chapter;
    }

    public get isAvailable() {
        return this._lessonsProgression.some(lessonProgress => lessonProgress.isAvailable);
    }

    public get isCompleted() {
        return this._lessonsProgression.every(lessonProgress => lessonProgress.isCompleted);
    }
}
