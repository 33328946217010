import { LessonCategory } from "./LessonCategory";

export class LessonReferenceModel {
    dto: LessonReference.LessonReferenceDto;

    constructor(dto: LessonReference.LessonReferenceDto) {
        this.dto = dto;
    }

    get id(): string {
        return this.dto.id.toString();
    }

    get lessonName(): string {
        return this.dto.lessonName;
    }

    get contentRef(): string {
        return this.dto.filePath;
    }

    get characterId(): string | null {
        return this.dto.characterId;
    }

    get lessonCategory(): LessonCategory {
        return new LessonCategory(this.dto.category ?? LessonCategory.FUNDAMENTALS_ID);
    }
}

export namespace LessonReference {
    export interface LessonReferenceDto {
        id: string;
        filePath: string;
        lessonName: string;
        previewObjective: string;
        characterId: string | null;
        lessonAttributes: {
            m365Apps: string[];
        }
        category: string;
    }
}
