import { LessonCardList } from "./LessonCardList";
import { useTranslation } from "react-i18next";
import { useDashboardContext } from "../../../context/dashboard/DashboardContext";
import { useGetChapterDetails } from "../../../hooks/chapters/UseChapters";
import { CommonLoading } from "../../loading/CommonLoading";

export const LessonCardListContainer = () => {
    const { t } = useTranslation();
    const { currentChapterId } = useDashboardContext();
    const { data: lessonsForChapter, isLoading, error } = useGetChapterDetails(currentChapterId);

    if (isLoading) {
        return <CommonLoading/>;
    }

    // TODO - handle error properly
    if(error) {
        throw error;
    }

    return (
        <>
            <LessonCardList lessons={lessonsForChapter?.lessonsForChapter ?? []} />
        </>
    );
};
