import { app, calendar } from "@microsoft/teams-js";
import { Button, Col, Row } from "react-bootstrap";
import { LessonCalendarPageModel } from "../../hooks/lesson/LessonContentModel";
import LinkTransformer from "../../services/markdown/LinkTransformer";
import LessonMarkdownContent from "./markdown/LessonMarkdownContent";
import LessonPageImage from "./markdown/LessonPageImage";
import useAnalytics, { LinkOrigin, LinkSubOrigin } from "../../hooks/analytics/UseAnalytics";
import { useTranslation } from "react-i18next";

interface Props {
  content: LessonCalendarPageModel;
}

export default function LessonCalendarPage(props: Props) {
  const { meetingSubject, meetingContent, header, body, illustration } = props.content;
  const { trackLinkClicked } = useAnalytics();
  const { t } = useTranslation();

  const scheduleMeeting = () => {
    const subject = encodeURIComponent(meetingSubject);
    const content = encodeURIComponent(meetingContent);
    const deeplinkUrl = `https://teams.microsoft.com/l/meeting/new?subject=${subject}&content=${content}`;

    trackLinkClicked({ destination: LinkOrigin.Calendar, source: LinkOrigin.App, subSource: LinkSubOrigin.Lesson });
    app.openLink(deeplinkUrl);
  }

  const canScheduleMeeting = () => {
    return calendar.isSupported();
  }

  if (!!illustration?.url) {
    return (
      <div>
        <h1>{header}</h1>
        <Row>
          <Col md={8} lg={10}>
            <LessonMarkdownContent content={body} />
            <Button onClick={scheduleMeeting}>
              { t('lesson.calendarButton') }
            </Button>
          </Col>
          <Col md={4} lg={2}>
            {canScheduleMeeting() && <LessonPageImage src={LinkTransformer.resolveRemoteStorageUrl(illustration.url)} />}
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div>
      <h1>{header}</h1>
      <LessonMarkdownContent content={body} />
      <Button onClick={scheduleMeeting}>
        { t('lesson.calendarButton') }
      </Button>
    </div>
  );
}
