import { Button } from "react-bootstrap";
import { AzureFunctions } from "../../../services/backend/AzureFunctions";
import { useContext } from "react";
import { TeamsFxContext } from "../../Context";
import { useUserInfo } from "../../../hooks/user-profile/useUserInfo";
import { CommonLoading } from "../../loading/CommonLoading";
import { useLanguage } from "../../../hooks/language/useLanguage";

export const DebugNotificationsTool = () => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { data: userInfo, isLoading: isLoadingUserInfo, error: userInfoError } = useUserInfo();
  const azureFunctions = new AzureFunctions(teamsUserCredential!);
  const locale = useLanguage().getCurrentLanguage();
  const isLoading = isLoadingUserInfo;

  if (isLoading) {
    return <CommonLoading />
  }

  if (userInfoError) {
    throw userInfoError;
  }

  const triggerInactiveNotification = async () => {
    await azureFunctions.debugReminderNotification(userInfo!, locale, "Inactive");
  };

  const triggerImproveNotification = async () => {
    await azureFunctions.debugReminderNotification(userInfo!, locale, "Improve");
  };

  const triggerCongratulateNotification = async () => {
    await azureFunctions.debugReminderNotification(userInfo!, locale, "Congratulate");
  };

  return <div className="mt-4">
    <Button variant="secondary" onClick={triggerInactiveNotification}>Trigger inactive notification</Button>
    <br />
    <br />
    <Button variant="secondary" onClick={triggerImproveNotification}>Trigger improve notification</Button>
    <br />
    <br />
    <Button variant="secondary" onClick={triggerCongratulateNotification}>Trigger congratulate notification</Button>
    <br />
  </div>;
};
