import ReactGA from "react-ga4";
import config from "../../config";
import { app } from "@microsoft/teams-js";

const initialize = async (userId?: string) => {
    userId = userId || "unknown";
    
    const appContext = await app.getContext();

    console.log(`[Analytics] Initializing Google Analytics with measurement ID: ${config.googleAnalyticsMeasurementId}`);
    // console.log(`[Analytics] User ID: ${userId}`);
    // console.log(`[Analytics] Tenant ID: ${appContext.user?.tenant?.id}`);

    ReactGA.initialize(config.googleAnalyticsMeasurementId, {
        // testMode: config.developerDebugEnabled,
        gaOptions: {
            anonymizeIp: true,
            allowAdFeatures: false,
            allowAdPersonalizationSignals: false,

            // To enable GA cookies in an cross-origin iframe setup, we need to set the SameSite and Secure attributes
            // (https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite)
            cookieFlags: 'SameSite=None;Secure',

            userId: userId, // NOTE: this is a ReactGA field that is translated to 'user_id'.

            // Custom fields:
            app_environment: config.appEnvironment,
            app_language: appContext.app.locale,
            tenant_id: appContext.user?.tenant?.id,
            version: config.analyticsVersion,
        }
    });
}

const trackPageView = async (page: string, title: string) => {
    ReactGA.send({ hitType: "pageview", page, title, });
}

const trackLessonViewed = async (parameters: { id: string, type: string, isMandatory?: boolean, isPriority?: boolean, aiComplexity: string, m365apps: string[], wasInProgress?: boolean, wasFinished?: boolean, curriculumIndex: number }) => {
    const { id, type, isMandatory, isPriority, aiComplexity, m365apps, wasInProgress, wasFinished, curriculumIndex } = parameters;

    ReactGA.event('lesson_viewed', {
        id: id,
        type: type,
        is_mandatory: convertBooleanToNumber(isMandatory),
        is_priority: convertBooleanToNumber(isPriority),
        ai_complexity: aiComplexity,
        m365_apps: m365apps.length === 0 ? 'None' : m365apps.join(','),
        was_in_progress: convertBooleanToNumber(wasInProgress),
        was_finished: convertBooleanToNumber(wasFinished),
        curriculum_index: curriculumIndex,
    });
}

const trackLessonFinished = async (parameters: { id: string, type: string, isMandatory?: boolean, isPriority?: boolean, aiComplexity: string, m365apps: string[], wasFinished?: boolean, curriculumIndex: number }) => {
    const { id, type, isMandatory, isPriority, aiComplexity, m365apps, wasFinished, curriculumIndex } = parameters;

    ReactGA.event('lesson_finished', {
        id: id,
        type: type,
        is_mandatory: convertBooleanToNumber(isMandatory),
        is_priority: convertBooleanToNumber(isPriority),
        ai_complexity: aiComplexity,
        m365_apps: m365apps.length === 0 ? 'None' : m365apps.join(','),
        was_finished: convertBooleanToNumber(wasFinished),
        curriculum_index: curriculumIndex,
    });
}

const trackChapterFinished = async (parameters: { id: number, index: number, lessonIds: string[] }) => {
    const { id, index, lessonIds } = parameters;

    ReactGA.event('chapter_finished', {
        id: id,
        index: index,
        lesson_ids: lessonIds.join(','),
    });
}

export enum LinkOrigin {
    Conversations = "Conversations",
    Calendar = "Calendar",
    App = "App",
};

export enum LinkSubOrigin {
    Intake = "Intake",
    Practice = "Practice",
    Dashboard = "Dashboard",
    Lesson = "Lesson",
};
const trackLinkClicked = async (parameters: { destination: LinkOrigin, subDestination?: LinkSubOrigin, source: LinkOrigin, subSource?: LinkSubOrigin }) => {
    const { destination, subDestination, source, subSource } = parameters;

    ReactGA.event('link_clicked', {
        destination: destination,
        sub_destination: subDestination || "",
        source: source,
        sub_source: subSource || "",
    });
}

const convertBooleanToNumber = (value?: boolean) => {
    if (value === undefined || value === null) {
        return -1;
    }

    return value ? 1 : 0;
}

const useAnalytics = () => {
    return { initialize, trackPageView, trackLessonViewed, trackLessonFinished, trackChapterFinished, trackLinkClicked };
}

export default useAnalytics;