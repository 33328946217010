import { Image, ImageProps } from "react-bootstrap";
import "./CoachImage.css";

export interface ICoachImageProps extends ImageProps {
}

export const CoachImage: React.FC<ICoachImageProps> = (props) => {
    return (
        <Image className="coach-image" {... props} />
    )
}