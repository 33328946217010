import React from "react";
import { Spinner } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

export const CommonLoading : React.FC = () => {
    const { t } = useTranslation();

    return (<>
        <Spinner/>
        <span><Trans i18nKey="common.loading">Loading...</Trans></span>
    </>);
};