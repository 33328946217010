import { Container, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LessonReferenceModel } from "../../../hooks/lesson/LessonReferenceModel";
import { useGetLessonReferences } from "../../../hooks/lesson/UseLessons";
import { CommonLoading } from "../../loading/CommonLoading";

interface IDebugLessonOverviewProps {
    requestClose?: () => void;
}

export const DebugLessonOverview = (props: IDebugLessonOverviewProps) => {
    const { requestClose } = props;

    const navigate = useNavigate();
    const { data: lessons, isLoading: isLoadingLessons } = useGetLessonReferences();

    const startLesson = (lesson: LessonReferenceModel) => {
        navigate(`/lesson/${lesson.id}`);
        requestClose?.();
    };

    if (isLoadingLessons) {
        return <CommonLoading />;
    }

    return (
        <div>
            <p>Click on a lesson to start it.</p>
            <Table striped hover>
                <tbody>
                    {lessons?.map((lesson: LessonReferenceModel, index: number) => (
                        <tr key={`lesson-row-${index}`} onClick={() => startLesson(lesson)} style={{ cursor: 'pointer' }}>
                            <td>{lesson.id}</td>
                            <td>{lesson.lessonName}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};
