import { createContext, useContext, useEffect } from "react";
import useAnalytics from "../../hooks/analytics/UseAnalytics";

export const AnalyticsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { initialize } = useAnalytics();

    useEffect(() => {
        initialize();
    }, []);

    const context: AnalyticsContextType = {
    };
    return (
        <AnalyticsContext.Provider value={context}>
            {children}
        </AnalyticsContext.Provider>
    );
};

interface AnalyticsContextType {

}

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(undefined);

export const useAnalyticsContext = () => {
    const context = useContext(AnalyticsContext)
    if (!context) {
        throw new Error('useAnalyticsContext must be used within a DashboardProvider')
    }
    return context
}
