import { Trans, useTranslation } from "react-i18next";

import { CoachImage } from "../ui/CoachImage";
import { useUserInfo } from "../../hooks/user-profile/useUserInfo";
import { CommonLoading } from "../loading/CommonLoading";
import "./Dashboard.css"
import { Container, Row } from "react-bootstrap";
import useCalendar from "../../hooks/calendar/UseCalendar";
import { Link } from "react-router-dom";
import useAnalytics, { LinkOrigin, LinkSubOrigin } from "../../hooks/analytics/UseAnalytics";

export const DashboardHeaderTitle = () => {
    const { data: userInfo, isLoading: isLoadingUserInfo, error: userInfoError } = useUserInfo();
    const { t } = useTranslation();
    const { openCalendar } = useCalendar();
    const { trackLinkClicked } = useAnalytics();

    const scheduleMeetingForNextWeek = async () => {
        const dateTomorrowAtTen = new Date();
        dateTomorrowAtTen.setDate(dateTomorrowAtTen.getDate() + 1);
        dateTomorrowAtTen.setHours(10, 0, 0, 0);
        const subject = t('dashboard.calendarReminder.subject');
        const content = t('dashboard.calendarReminder.content');

        const endDateTime = new Date(dateTomorrowAtTen.getTime() + 30 * 60 * 1000); // Meeting duration = 30 minutes.

        trackLinkClicked({ destination: LinkOrigin.Calendar, source: LinkOrigin.App, subSource: LinkSubOrigin.Dashboard });
        await openCalendar({
            subject: subject,
            content: content,
            startTime: dateTomorrowAtTen,
            endTime: endDateTime,
        });
    }

    // TODO - properly handle error
    if (userInfoError) {
        throw userInfoError;
    }

    const isLoading = isLoadingUserInfo || !userInfo;
    if (isLoading) {
        return <CommonLoading />
    }

    const firstName = userInfo.displayName;

    return (
        <>
            <div className="row d-flex align-items-center dashboard-title-container">
                <div className="col-3 col-sm-2 col-md-1 d-flex align-items-center hero-logo">
                    <CoachImage src="dashboard/coach-icon.png" />
                </div>
                <div className="col d-flex align-items-center">
                    <Container className="dashboard-title-text-container">
                        <Row>
                            <h2>{t('dashboard.title', { firstName })}</h2>
                        </Row>
                        <Row>
                            <span className="dashboard-subtitle">
                                <Trans i18nKey="dashboard.description" components={{
                                    calendarLink: <Link to="#" onClick={scheduleMeetingForNextWeek} />
                                }} />
                            </span>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
};