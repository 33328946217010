

export class LessonCategory {
    static readonly FUNDAMENTALS_ID = "fundamentals";

    private categoryId: string;

    constructor(categoryId: string) {
        this.categoryId = categoryId;
    }

    get achievementsIconImageUrl(): string {
        return `achievements/categories/${this.id}.png`;
    }

    get displayNameLocalizationKey(): string {
        return `categories.${this.id}`;
    }

    get id(): string {
        return this.categoryId.toLowerCase();
    }

    get isFundamentals() {
        return this.id === LessonCategory.FUNDAMENTALS_ID;
    }
}

