import { DashboardStepper } from "./DashboardStepper";
import { DashboardHeaderTitle } from "./DashboardHeaderTitle";
import "./Dashboard.css"


export const DashboardHeader = () => {
    return (
        <>
            <DashboardHeaderTitle />
            <div className="dashboard-stepper-container">
                <DashboardStepper />
            </div>
        </>
    );
};