import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BaseLessonStepModel, LessonCalendarPageModel, LessonImagePageModel, LessonVideoPageModel } from "../../hooks/lesson/LessonContentModel";
import LessonCalendarPage from "../lesson-pages/LessonCalendarPage";
import LessonImagePage from "../lesson-pages/LessonImagePage";
import LessonVideoPage from "../lesson-pages/LessonVideoPage";
import Panel from "../ui/Panel";
import './Onboarding.css';
import { CommonLoading } from "../loading/CommonLoading";
import { CommonButton } from "../ui/CommonButton";
import { useOnboardingContext } from "../../context/onboarding/OnboardingContext";
import useAnalytics from "../../hooks/analytics/UseAnalytics";
import { useEffect } from "react";
import { OnboardingStepper } from "./OnboardingStepper";
import { NoticePopup } from "../notice-popup/NoticePopup";

export const Onboarding = () => {
    const { t } = useTranslation();

    const { currentPage, setCurrentPage, nextPage, hasPreviousPage, previousPage, onboarding, isLoading: isLoadingOnboarding, getNumberOfSteps, isNextStepLoading, isIntakeLoading } = useOnboardingContext();
    const { trackPageView } = useAnalytics();

    useEffect(() => {
        trackPageView("/onboarding", "Onboarding");
    }, []);

    const isLoading = isLoadingOnboarding;
    if (isLoading) {
        return <CommonLoading />
    }

    const renderPage = (currentPage: number) => {
        const basePageContent = onboarding!.steps[currentPage] as BaseLessonStepModel;
        switch (basePageContent.componentType) {
            case 'lesson.calendar-page':
                return (<LessonCalendarPage content={basePageContent as LessonCalendarPageModel} />);
            case 'lesson.video-page':
                return (<LessonVideoPage content={basePageContent as LessonVideoPageModel} />);
            case 'lesson.image-page':
                return (<LessonImagePage content={basePageContent as LessonImagePageModel} />);
            default:
                console.warn('[Onboarding] Unknown component type, defaulting to image page.');
                return (<LessonImagePage content={basePageContent as LessonImagePageModel} />);
        }
    }

    const getNextButtonLabel = () => {
        if (currentPage === getNumberOfSteps() - 1) {
            return t('onboarding.finishButton');
        }

        return t('onboarding.nextButton');
    }

    return (
        <Container className="onboarding-container">
            <Panel className="onboarding-content-panel">
                <Container>
                    <Row className="onboarding-content">
                        {renderPage(currentPage)}
                    </Row>
                </Container>
            </Panel>
            <Container>
                <Row className="py-4 align-items-center">
                    <Col md={5} lg={5} className="d-flex justify-content-center">
                        <CommonButton disabled={!hasPreviousPage()} onClick={previousPage} style={{ visibility: hasPreviousPage() ? "visible" : "hidden" }}>{t('onboarding.previousButton')}</CommonButton>
                    </Col>
                    <Col md={2} lg={2} className="d-flex justify-content-center">
                        <OnboardingStepper currentStepIndex={currentPage} stepsCount={getNumberOfSteps()} onStepPressed={setCurrentPage}/>
                    </Col>
                    <Col md={5} lg={5} className="d-flex justify-content-center">
                        <CommonButton onClick={nextPage}
                            disabled={isNextStepLoading}
                            isLoading={isNextStepLoading}
                            buttonLoadingLabel={t("onboarding.nextStepLoading")}
                            isAttractEnabled={isNextStepLoading}>
                            {getNextButtonLabel()}
                        </CommonButton>
                    </Col>
                </Row>
            </Container>
            <NoticePopup headerTranslationKey="chatRedirection.header" bodyTranslationKey="chatRedirection.body" isBlocking={true} show={isIntakeLoading} />
        </Container>
    );
}
